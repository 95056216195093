import Sdk, { Asset } from 'fno-sdk';
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import "./AssetTooltip.scss";
import useMousePosition from 'hooks/useMousePosition';
import appColors from 'resources/appColors';
import _ from 'lodash';

interface Props {
    sdk: Sdk;
    loading: boolean;
    color?: string;
    onHover?: (asset: Asset) => ReactElement
}

const _skdTag = "AssetTooltip"



/**
 * AssetTooltip is a react component, it is used to display the location code of an asset when hovering it in the map.
 * It is meant to be used in the MapRender component.
 * @param sdk Sdk: The SDK map instance.
 * @param loading boolean: A boolean indicating if the SDK is loading.
 * @param color string: The color to highlight the asset with. Defaults to `appColors.orange`.
 * @returns JSX.Element
 */
const AssetTooltip: React.FC<Props> = ({sdk, loading, color = appColors.orange, onHover}) => {
   const { t } = useTranslation(nameOf({AssetTooltip}), { useSuspense: false});
   const mousePosition = useMousePosition();
   // ref for getting value inside unity sdk callback (ex: registerOnHoverLeaveAsset)
   const _assetHovered = useRef<Asset | undefined>();


   useEffect(() => {
    if(sdk && !loading){ 
        sdk.asset.registerOnHoverAsset((asset) => {
            _assetHovered.current = asset.asset as unknown as Asset;
            if(!asset.asset.isHighlight) 
            {
                sdk.asset.setHighlightAssetByIds([asset.asset.id], true, color);
            }
        }, _skdTag);

        sdk.asset.registerOnHoverLeaveAsset((asset) => {
            if(!_assetHovered.current?.isHighlight) 
            {
                sdk.asset.setHighlightAssetByIds([asset.asset.id], false, color);
            }
            _assetHovered.current = undefined;
        }, _skdTag);
    }
   }, [sdk, loading])


   useEffect(() => {
    return () => {
        if(sdk && sdk.asset)
        {
            sdk.asset.unRegisterOnHoverAsset(_skdTag);
            sdk.asset.unRegisterOnHoverLeaveAsset(_skdTag);
        }

    }
   }, [])

   if(!_assetHovered.current) return <></>

   const locationCodes = _.uniq(_.compact(_assetHovered.current.locationCodes));
   
    if(locationCodes.length > 0)
        return (<div className='asset-tooltip' style={{top: mousePosition.y ?? 0, left: mousePosition.x ?? 0}}>
            <div>
                {!onHover && locationCodes.map((locationCode, index) => <div key={index}>{locationCode}</div>)}
                {onHover && onHover(_assetHovered.current)}
            </div>
        </div>)
    else
        return <></>
   

   
}
export default AssetTooltip