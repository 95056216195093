import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import getCurrentTenant from 'backend/utils/getCurrentTenant';
import AuditFinalCompareRowRender from './AuditFinalCompareRowRender';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import { ROUTES } from 'resources/routes-constants';
import { useNavigate } from 'react-router-dom';
import appColors from 'resources/appColors';
import MapRender from 'components/mapControls/MapRender';
import { IntegralOptimization, MissionDetails, PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';
import checkPermissions from 'utility/checkPermissions';

export interface IOrderRow{
    ref: string;
    products: {
        ean: string;
        emplacement: string;
        quantity: string;
    }[];
    searchField: string;
}



const AuditFinalPathClientResult: React.FC = () => {
    const { t } = useTranslation(nameOf({AuditFinalPathClientResult}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentMap = useAppSelector(state => state.map.currentMap);
    const loadingAuditData = useAppSelector(state => state.audit.loadingList || state.audit.loadingOptimizationResult);
    const auditRawFileSelected = useAppSelector(state => state.audit.auditRawFileSelected);
    const {
        sdk,
        loading,
        mapReady
    } = useFnoMapSdk(getCurrentUserToken(), getCurrentTenant()?.tenant ?? "", currentMap?.id ?? "");

    const [rowSelected, setRowSelected] = useState<IOrderRow | undefined>(undefined);
    //const [rows, setRows] = useState<IOrderRow[]>([]);
    //const rawData = useAppSelector(state => state.audit.data);
    const optimizationResult = useAppSelector(state => state.audit.optimizationResult);
    const [searchTerm, setSearchTerm] = useState('');
    

    useEffect(() => {
        document.title = t('Optimisation de chemin');
        return () => {
            sdk.hidePlayer();
        }
    }, [])
    
    useEffect(() => {
        if(!checkPermissions(PermissionsEnum.AuditList))
        {
            navigate(ROUTES.DASHBOARD)
        }
    }, [currentMap])
    

    /*useEffect(() => {
        console.time("AAA generateRowsToShow");
        if(!searchTerm) setRowsToShow(rows);
        else{
            console.log("AAA start filter", searchTerm, rows);
            const resultFound = rows.filter((row) => row.searchField.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
            setRowsToShow(resultFound)
            console.log("AAA end filter", resultFound);
        }

        console.timeEnd("AAA generateRowsToShow");

    }, [searchTerm, rows ])*/
    


   /* useEffect(() => {
        console.time("AAA generateRows");
        if(rawData && auditRawFileSelected)
        {
            const group: IOrderRow[] = _.map(_.groupBy(rawData.rows, rawData.fields[auditRawFileSelected?.columnsBindingData.fieldMission] ), (items, key) => {

                const products = [
                    {ean:"start", emplacement:"start", quantity:1},
                    ...items.map((product) => {
                        return {
                            ean: product[rawData.fields[auditRawFileSelected?.columnsBindingData.fieldEan]] as string,
                            emplacement: product[rawData.fields[auditRawFileSelected?.columnsBindingData.fieldEmplacement]] as string,
                            quantity: product[rawData.fields[auditRawFileSelected?.columnsBindingData.fieldQuantity]] as string
                        }
                    }),
                    {ean:"end", emplacement:"end", quantity:1}
                ]


                return {
                    ref: key,
                    products: products,
                    // merge all fields in one for search purpose
                    searchField: key + " " + _.join(_.values(items), " ").toLocaleLowerCase() 
                }  as IOrderRow
            });

            setRows(group);
        
        }
        else{
            setRows([]);
        }
        console.timeEnd("AAA generateRows");
    }, [rawData, auditRawFileSelected])*/


    useEffect(() => {
        if(!rowSelected)
        {
            clearItineraries();
        }
        else
        {
            showItinerary(rowSelected);
        }
    }, [rowSelected])


    const showItinerary = async (order: IOrderRow) => {
      
        // optimized
        await sdk?.pathfinding.setItineraryToAssetsByLocationCode(order.products.map(item => item.emplacement), appColors.blue);
        // original = "#cd5c5c"
    }

    const clearItineraries = async () => {
        await sdk?.pathfinding.clearItineraries();
    }


    const onHoverEnter = (order: IOrderRow) => {
        if(!rowSelected)
        {
            showItinerary(order)
        }
    }   
    const onHoverLeave = (order: IOrderRow) => {
        if(!rowSelected)
        {
            clearItineraries()
        }
    }

    const oldCost = _.sum(_.map(optimizationResult?.path.paths, (path) => path.original.totalDistance));
    const finalCost = _.sum(_.map(optimizationResult?.batchingPathClient.missions, (mission) => mission.totalDistance));
    const gainpourcent = -((finalCost - oldCost) / oldCost) * 100;
    const gainPourcentString = gainpourcent.toFixed(2);


    const finalOrderFiltered = _.fromPairs(
        _.filter(_.toPairs(optimizationResult?.batchingPathClient.missions), ([key, value]) => {
            return key.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase());
        })
      ) as Record<string, MissionDetails>;

    return (
        <div id="statistic-picking-optimization-path">
             {<PageHeader title={t('Digital twin')}/>}
             <Row style={{height: "100%"}}>
                <Col sm="8" style={{height: "100%"}}>
                    <MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.blue
                        }}/>
                </Col>
                <Col id="col-table-container" sm="4">
                    {mapReady && !loadingAuditData && <div>
                        <h4>
                            {t("Regroupement avec chemin client")} 
                            {<Badge  className='ms-2 fs-6' bg="orange">{`${t("Gain moyen final")} ${gainPourcentString}%`}</Badge>}
                            <Button size='sm' variant='link' title={t('Centrer caméra')} className='ms-1 float-end' onClick={() => sdk?.camera.resetCamera()}>
                                <FontAwesomeIcon icon={["fas", "bullseye"]} />
                            </Button>
                        </h4>
                        <div>
                            {sdk && <>
                            <div>
                                <input className='w-100 search-input mb-2' placeholder={t("Rechercher une mission")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                            </div>
                            <AuditFinalCompareRowRender 
                                        sdk={sdk} 
                                        onClick={setRowSelected} 
                                        finalOrders={finalOrderFiltered} 
                                        onHoverEnter={onHoverEnter} 
                                        onHoverLeave={onHoverLeave} />
                            </>}
                        </div>
                    </div>}
                    {loadingAuditData && <div className='loading-map-panel'><Loading text={t("Chargement des données")}/></div>}
                </Col>
             </Row>
        </div>
    )
}

export default AuditFinalPathClientResult
