import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import Switch from "react-switch";
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OptimizationSettingCreate, OptimizationSettingDetails, RunnerPreloadMatrixParams } from "backend/ApiAdminDefinition/data-contracts";
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import PickingConfigurationForm, { PickingConfigurationFormInputs } from 'components/Admin/forms/PickingConfigurationForm';
import { FormikHelpers } from 'formik';
import { downloadFile } from 'utility/downloadFile';
import { createAndUploadOptimizationSettingFilesThunk } from 'store/reducers/Admin/ClientMapPickingSetting/thrunks/createAndUploadOptimizationSettingFilesThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { loadOptimizationSettingThunks } from 'store/reducers/Admin/ClientMapPickingSetting/thrunks/loadOptimizationSettingThunks';
import { enableOptimizationSettingThrunks } from 'store/reducers/Admin/ClientMapPickingSetting/thrunks/enableOptimizationSettingThrunks';
import { deleteOptimizationSettingThunk } from 'store/reducers/Admin/ClientMapPickingSetting/thrunks/deleteOptimizationSettingThunk';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import apiAdminDefinition from 'backend/apiAdminDefinition';
import EditJsonRaw from 'components/Admin/EditJsonRaw';
import ButtonFno from 'components/inputs/ButtonFno';
import readFileFromUrl from 'utility/readFileFromUrl';

const AdminClientMapPickingSetting: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapPickingSetting}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const selectedAppClient = useAppSelector(state => state.userProfil.currentTenant);
    const allMaps = useAppSelector(state => state.adminClientMap.maps);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showImportModal, setShowImportModal] = useState(false);
    const [loadingImportModal, setLoadingImportModal] = useState(false);
    const [generateJsonStatus, setGenerateJsonStatus] = useState<undefined | "loading" | RunnerPreloadMatrixParams>(undefined);
    const { clientId, mapId } = useParams();

    const currentMapName = allMaps.find(m => m.id == mapId)?.name ?? "N/C";

    const {
        loadingList,
        loadingForm,
        optimizationSettings
    } = useAppSelector(state => state.adminOptimizationSetting);

    useEffect(() => {
        document.title = t('[Admin] Les configuration picking');
        if(clientId && mapId && mapId != "new")
        {
            loadSettings();
        }
    }, [mapId])


    const loadSettings = () => {
        if(clientId && mapId)
        {
            dispatch(loadOptimizationSettingThunks(mapId));
        }   
    }


    const onCreate = (values: PickingConfigurationFormInputs, {setFieldError}:FormikHelpers<PickingConfigurationFormInputs>) => {
        if(clientId && mapId)
        {
            dispatch(createAndUploadOptimizationSettingFilesThunk(mapId, values,
                {
                    dictionnaryFile: values.dictionnaryFile,
                    matrixComputeFile: values.matrixComputeFile,
                    matrixFile: values.matrixFile,
                    pointsFile: values.pointsFile
                },
                {
                onSuccess: (result) => {
                    setShowCreateModal(false);
                    toast.success(t("La configuration a bien été créée"))
                    if(values.enabled)
                    {
                        onEnabledSetting(result);
                    }
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }

    const onImport = async (jsonRaw: any) => {
        if(clientId && mapId)
        {
            // check missing field
            const fieldRequired = ["tenant", "optimizationSettingName", "urlLocationCode", "urlCalculationMatrix", "urlDistanceMatrix", "urlPoints"];
            const missingFields = fieldRequired.filter(f => !jsonRaw[f]);
            if(missingFields.length > 0)
            {
                toast.error(t("Les attributs suivants sont manquants: {{missingFields}}", {missingFields: missingFields.join(", ")}));
                return;
            }
            const json = jsonRaw as RunnerPreloadMatrixParams;

            // check tenant match with current selected
            if(json.tenant != selectedAppClient?.tenant)
            {
                toast.error(<>
                    <div>{t("Le client ne correspond pas au client actuellement séléctionné")}</div>
                    <ul>
                        <li>{t("Séléctionné:")}<b>{selectedAppClient?.tenant}</b></li>
                        <li>{t("Import:")}<b>{json.tenant}</b></li>
                    </ul>
                </>);
                return;
            }

            // Check for name already exists
            if(optimizationSettings.some(s => s.name == json.optimizationSettingName))
            {
                toast.error(<>
                    <div>{t("Une configuration avec ce nom existe déjà")}</div>
                    <ul>
                        <li><b>{json.optimizationSettingName}</b></li>
                    </ul>
                </>);
                return;
            }

            try{

                const [fileCalculationMatrix, fileDistanceMatrix, filePoints, fileLocationCode] = await Promise.all([
                    readFileFromUrl(json.urlCalculationMatrix ?? ""),
                    readFileFromUrl(json.urlDistanceMatrix ?? ""),
                    readFileFromUrl(json.urlPoints ?? ""),
                    readFileFromUrl(json.urlLocationCode ?? "")
                ])

                setLoadingImportModal(true);
                dispatch(createAndUploadOptimizationSettingFilesThunk(mapId, 
                    {
                        name: json.optimizationSettingName
                    } as OptimizationSettingCreate,
                    {
                        dictionnaryFile: fileLocationCode,
                        matrixComputeFile: fileCalculationMatrix,
                        matrixFile: fileDistanceMatrix,
                        pointsFile: filePoints
                    },
                    {
                    onSuccess: (result) => {
                        setShowImportModal(false);
                        toast.success(t("La configuration a bien été importée"))
                        setLoadingImportModal(false);
                    },
                    onError: (error) => {
                        parseApiErrorToast(error);
                        setLoadingImportModal(false);
                    }
                }))
            }
            catch(e)
            {
                toast.error(t("Impossible de télécharger les fichiers"));
                return;
            }
        }
    }

    const onEnabledSetting = (setting: OptimizationSettingDetails) => {
        if(clientId && mapId && setting.enabled == false)
        {

            dispatch(enableOptimizationSettingThrunks(mapId, setting.id, {
                onSuccess: (settingUpdated) => {
                    toast.success(t('La configuration a bien été activée'))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }));
        }
        
    }

    const onDelete = (setting: OptimizationSettingDetails, closeModal: () => void) => {
        if(clientId && mapId)
        {
            dispatch(deleteOptimizationSettingThunk(mapId, setting.id, {
                onSuccess: (result) => {
                    toast.success(t('La configuration a bien été supprimée'))
                    closeModal();
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }));
        }
    }


    const generateJson = async (setting: OptimizationSettingDetails) => {
        if(clientId && mapId)
        {
            setGenerateJsonStatus("loading");
            const result = await apiAdminDefinition.pickingRunner.pickingRunnerPreloadDataList({
                mapId: mapId,
                tenant: selectedAppClient?.tenant ?? "",
                settingId: setting.id
            });

            setGenerateJsonStatus(result.data)

        }
    }

    const columns: TableColumn<OptimizationSettingDetails>[] = [
        {
            id:'enabled',
            name: t('Active'),
            sortable: true,
            width:"110px",
            selector: row => row.enabled,
            cell: row =>  <Switch checked={row.enabled} onChange={(enabled) => {onEnabledSetting(row)}}/>
        },
        {
            id:'version',
            name: t('Version'),
            sortable: true,
            width:"110px",
            selector: row => row.idShort,
        },
        {
            id:'name',
            name: t('name'),
            sortable: true,
            selector: row => row.name,
        },
        {           
            name:<div className='text-center'>{t('Matrice de distances')}</div>,
            center:true,
            cell: (row, index, column, id) =>{
                return row.downloadUrlMatrix && <div>
                    <Button size='sm' variant='link' title={t('Télécharger le fichier')} className='ms-1' onClick={() => downloadFile(row.downloadUrlMatrix, "Matrice_de_distance.txt")}>
                        <FontAwesomeIcon icon={["fas", "download"]} />
                    </Button>
                </div>}
        },
        {           
            name:<div  className='text-center'>{t('Matrice de calculs')}</div>,
            center:true,
            cell: (row, index, column, id) =>{
                return row.downloadUrlComputeMatrix && <div>
                    <Button size='sm' variant='link' title={t('Télécharger le fichier')} className='ms-1' onClick={() => downloadFile(row.downloadUrlComputeMatrix, "Matrice_de_calculs.txt")}>
                        <FontAwesomeIcon icon={["fas", "download"]} />
                    </Button>
                </div>}
        },
        {           
            name:<div  className='text-center'>{t('Refs client')}</div>,
            center:true,
            cell: (row, index, column, id) =>{
                return row.downloadUrlIndexes && <div>
                    <Button size='sm' variant='link' title={t('Télécharger le fichier')} className='ms-1' onClick={() => downloadFile(row.downloadUrlIndexes, "Refs_client.txt")}>
                        <FontAwesomeIcon icon={["fas", "download"]} />
                    </Button>
                </div>}
        },
        {           
            name:<div  className='text-center'>{t('Vecteurs position 2D')}</div>,
            center:true,
            cell: (row, index, column, id) =>{
                return row.downloadUrlPoints && <div>
                    <Button size='sm' variant='link' title={t('Télécharger le fichier')} className='ms-1' onClick={() => downloadFile(row.downloadUrlPoints, "Vecteurs_position_2d.txt")}>
                        <FontAwesomeIcon icon={["fas", "download"]} />
                    </Button>
                </div>}
        },
        {
            id:'author',
            name: t('Auteur'),
            sortable: true,
            selector: row => row.author
        },
        {
            id:'dateCreated',
            name: t('Créé le'),
            sortable: true,
            selector: row => `${row.dateCreated ? dayjs(row.dateCreated).unix(): ""}`,
            cell: row => `${row.dateCreated ? dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm"): ""}`
        },
        {
            id:'jsonFormat',
            name: t('Json'),
            sortable: false,
            cell: row => <div>
                <Button size='sm' variant='primary' onClick={() => generateJson(row)}>{t('Json')}</Button>
            </div>
        }
    ];

    const lastSettingId = _.head(_.orderBy(optimizationSettings, ["idShort"], ["desc"]));
    const nextId = lastSettingId ? lastSettingId.idShort + 1 : 1;


    return <>
    <TableWithCrudComponent 
        entities={optimizationSettings} 
        columns={columns}
        loadingForm={loadingForm}
        loadingList={loadingList}
        onDelete={onDelete}
        onClickCreateButton={() => setShowCreateModal(true)}
        translations={{
            tableTitle: t("Configuration de la carte"),
            noEntityText: t("Aucune configuration disponible"),
            createTitle: t("Ajouter une configuration"),
            createButtonText: t("Ajouter une configuration"),
            deleteText: (entity) => (t("Voulez-vous supprimer la configuration ?")),
            deleteTitle: (entity) => (t("Supprimer la configuration ") + entity.name),
            updateText: (entity) => (t("Modifier la configuration ")  + entity.name),
        }}
        customHeader={<>
            <ButtonFno sm color='blue' className='me-2' onClick={() => setShowImportModal(true)}><FontAwesomeIcon icon={["fas", "download"]} /> {t("Importer une configuration")}</ButtonFno>
        </>}
        fieldSearchable={(entity) => [
            entity.idShort,
            entity.id,
            entity.author,
            entity.name
        ]}
        />
           {(showCreateModal) && <Modal dialogClassName='modal-fno' size='lg' show={true} onHide={() => setShowCreateModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Créer une nouvelle configuration picking")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PickingConfigurationForm 
                        onCancel={() => setShowCreateModal(false)} 
                        onSubmit={onCreate} 
                        initialValues={{
                            name: "V"+ nextId,
                            enabled: false
                        }}
                        loading={loadingForm}/>
                </Modal.Body>
            </Modal>}


            {generateJsonStatus && <Modal dialogClassName='modal-fno' size='xl' show={true} onHide={() => setGenerateJsonStatus(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("JSON envoyé au runner")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {generateJsonStatus == "loading" && <Loading text='En cours de telechargement ...'/>}
                    {generateJsonStatus != "loading" &&  <EditJsonRaw onCancel={() => setGenerateJsonStatus(undefined)}  value={generateJsonStatus} />}
                </Modal.Body>
            </Modal>}

            {showImportModal && <Modal dialogClassName='modal-fno' size='lg' show={true} onHide={() => setShowImportModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Importer un json de configuration pour la carte {{mapName}}", {mapName: currentMapName})}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditJsonRaw onCancel={() => setShowImportModal(false)} loading={loadingImportModal} onSubmit={onImport} value={{}} />
                </Modal.Body>
            </Modal>}
        </>
}

export default AdminClientMapPickingSetting
