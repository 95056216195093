import { useEffect, useState } from 'react';
import Sdk from 'fno-sdk';

/**
 * singleton for fno map sdk
 */
const useFnoMapSdk = (token: string, tenant: string, mapId?: string, onMapLoad?: (sdk: Sdk) => void) => { 

  const sdk =  window.fnoSdk || new Sdk();
  const [loadingMap, setLoadingMap] = useState(false);
  const [loadingEngine, setLoadingEngine] = useState(false);
  const [currentMapId, setCurrentMapId] = useState<string|undefined>(undefined);

  const [mapReady, setMapReady] = useState(sdk.getCurrentState() == "ready");
  const [engineReady, setEngineReady] = useState(sdk.getCurrentState() != "waitingEngineInit");

  useEffect(()=> {
    if(sdk.getCurrentState() == 'waitingEngineInit')
    {
      setLoadingEngine(true);
      sdk.setConfig({
        //storageUrl: "https://storagefnodev.blob.core.windows.net/sdk/webgl/1.1.3/",
        baseUrlApi: `${process.env.REACT_APP_API_URL}/`
      });
      sdk.initEngine()
        .then(() => setEngineReady(true))
        .finally(() => setLoadingEngine(false));
    }
  }, [])


  useEffect(()=> {
    sdk.setTokenAndTenant(token, tenant);
  }, [token, tenant])

  useEffect(()=> {
    if(engineReady && currentMapId && sdk.getCurrentState() == 'waitingMapInit')
    {
      setLoadingMap(true);
      setMapReady(false)
      sdk.loadMap(currentMapId)
          .then(() => {
            if(onMapLoad) onMapLoad(sdk);
            setMapReady(true);
          })
          .finally(() => setLoadingMap(false))
    }

  }, [currentMapId, engineReady])


  useEffect(()=> {
    if(currentMapId == undefined && mapId != undefined)
    {
      setCurrentMapId(mapId)
    }
    else if(engineReady && currentMapId != mapId)
    {
      sdk.destroy()
        .finally(() => setCurrentMapId(mapId))
    }

  }, [mapId, engineReady])

  window.fnoSdk = sdk;
  return {
    sdk,
    loadingMap,
    loadingEngine,
    loading: loadingEngine || loadingMap,
    mapReady
  };
};

export default useFnoMapSdk;