import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { useAppDispatch } from 'store/store';
import Sdk from 'fno-sdk';
import { SapDemoData } from '../DemoHeatmapSAP';
import { apiSAP } from 'backend/apiSAP/apiSap';
import sapDemoConfig from '../sapDemoConfig';
import WarehouseOrderData from 'backend/apiSAP/models/WarehouseOrder';
import Loading from 'components/Loading';
import { Accordion, Badge, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import SapOrderProductsTable from './SapOrderProductsTable';
import _ from 'lodash';
import appColors from 'resources/appColors';

type Props = {
    sdk: Sdk;
    data: SapDemoData | undefined;
  };


const PathPanel: React.FC<Props> = ({sdk, data}) => {
   const { t } = useTranslation(nameOf({PathPanel}), { useSuspense: false});
   const dispatch = useAppDispatch();
   
   const [loading, setLoading] = useState<boolean>(false);
   const [orderSelected, setOrderSelected] = useState<WarehouseOrderData | undefined>(undefined);

   useEffect(() => {

    return () => {
        sdk.asset.clearHighlights();
    }
   }, [])


   const onSelectOrder = (order: WarehouseOrderData) => {
    if(orderSelected == undefined || orderSelected.WarehouseOrder != order.WarehouseOrder)
    {
        const orderTasks = data? data.sapTasks.filter(t => t.WarehouseOrder == order.WarehouseOrder) : [];
        setOrderSelected(order);
        
        const locationCodes: string[] = [];
        for (let index = 0; index < orderTasks.length; index++) {
            const product = orderTasks[index];
            locationCodes.push(product.SourceStorageBin);
            locationCodes.push(product.DestinationStorageBin);
        }

        highlightAssets(_.uniq(locationCodes), true, appColors.blue);

    }
    else 
    {
        setOrderSelected(undefined);
        sdk.asset.clearHighlights();
    }
   }

   const highlightAssets = async (locationCodes: string[], enabled: boolean, color: string) => {
    await sdk.asset.clearHighlights();
    const assets = (await sdk.asset.getAssetDataByLocationCodes(locationCodes)).assets;
    sdk.asset.setHighlightAssetByIds(assets.map(m => m.id), enabled, color)
}



   return (
       <div>
           {loading && <Loading text='Loading orders ...'/>}
           {!loading && <>
            <Accordion activeKey={orderSelected?.WarehouseOrder}>
                {data && data.sapOrders.map((order, i) => {
                    //const gainPourcent = Math.abs(((order.optimizedDistance - order.initialDistance) / order.initialDistance) * 100);
                    const orderTasks = data? data.sapTasks.filter(t => t.WarehouseOrder == order.WarehouseOrder) : [];


                    return <Accordion.Item key={i} eventKey={order.WarehouseOrder}>
                        <Accordion.Header onClick={() => onSelectOrder(order)}>
                                <FontAwesomeIcon icon={["fas", "tag"]} /> 
                                <span className='ms-2'>{order.WarehouseOrder} </span>
                                <Badge className='ms-2' bg="primary"><FontAwesomeIcon icon={["fas", "user"]} /> {order.CreatedByUser}</Badge>
                                <Badge className='ms-2' bg="success"><FontAwesomeIcon icon={["fas", "tags"]} /> {orderTasks.length}</Badge>
                            </Accordion.Header>
                        <Accordion.Body>
                            <Row className='text-center mb-3'>
                                {/*<Col><Badge className='ms-2' bg="primary"><FontAwesomeIcon icon={["fas", "user"]} /> {order.CreatedByUser}</Badge></Col>
                                <Col><FontAwesomeIcon icon={["fas", "calendar"]} /> {dayjs(order.WhseOrderCreationDateTime).format("DD/MM/YYYY hh:mm")}</Col>*/}
                            </Row>
                            <div>
                                {order && order.WarehouseOrder == orderSelected?.WarehouseOrder && 
                                    <SapOrderProductsTable sdk={sdk} 
                                    sapOrder={order} 
                                    sapTasks={orderTasks}/>}
                            </div>
                            {/*
                            <Table striped bordered hover className={'order-list-item ' + (showInitial ? "show-initial " : "") + (showOptimzed ? "show-optimzed " : "")}>
                                <thead>
                                    <tr>
                                        <th rowSpan={2}>#</th>
                                        <th colSpan={2} className='text-center initial' > <input type='checkbox' checked={showInitial} onChange={(e) => setShowInitial(e.target.checked)}/> SAP</th>
                                    </tr>
                                    <tr>
                                       
                                        <th className='initial'>PRODUCT</th>
                                        <th className='initial'>LOCATION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order.products.map((item, i) => {
                                        return <tr key={i} >
                                            <td>{item.EWMResourceGroupQueueSqncNmbr}</td>
                                            <td className='initial'>{item.SKU} <Button size='sm' variant='link' onClick={() => copyToClipboard(item.SKU, "Copied")} title={"Copy SKU"}><FontAwesomeIcon icon={["fas", "copy"]} /></Button></td>
                                            <td className='initial'>{item.WarehouseOrderQueue} <Button size='sm' variant='link' onClick={() => copyToClipboard(item.WarehouseOrderQueue, "Copied")} title={"Copy location"}><FontAwesomeIcon icon={["fas", "copy"]} /></Button></td>
                                        </tr>
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        {<th className=''><FontAwesomeIcon icon={["fas", "flag-checkered"]} /> </th>
                                        <th className='text-center' colSpan={2}>{order.initialDistance} {t("mètres")}</th>
                                        <th className='text-center' colSpan={2}>{order.optimizedDistance} {t("mètres")}</th>}
                                   </tr>
                                   {<tr>
                                        <th className=''><FontAwesomeIcon icon={["fas", "percent"]} /> </th>
                                        <th className='text-center' colSpan={4}>{gainPourcent}%</th>
                                </tr>}
                                </tfoot>
                            </Table>
                            */}
                        </Accordion.Body>
                    </Accordion.Item>
                })}

                
            </Accordion>
           </>}
       </div>
   )
}
export default PathPanel