import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import "./DemoHeatmapSAP.scss";
import FloorsComponent from '../Components/FloorsComponent';
import ZoomComponent from '../Components/ZoomComponent';
import Loading from 'components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import MapRender from 'components/mapControls/MapRender';
import appColors from 'resources/appColors';
import getApiConfig from 'backend/utils/getApiConfig';
import { PickingOptimizationResult } from 'backend/ApiBackofficeDefinition/PickingOptimizationResult';
import { AuditFile } from 'backend/ApiBackofficeDefinition/AuditFile';
import HeatMapPanel from './Components/HeatMapPanel';
import LocationCodePanel from './Components/LocationCodePanel';
import PathPanel from './Components/PathPanel';
import { AnalysisDetails, AssetDetails, AuditRawFileDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import sapDemoConfig from './sapDemoConfig';
import { apiSAP } from 'backend/apiSAP/apiSap';
import { MapAsset } from 'backend/ApiBackofficeDefinition/MapAsset';
import _, { map } from 'lodash';
import WarehouseTaskData from 'backend/apiSAP/models/WarehouseTaskData';
import WarehouseOrderData from 'backend/apiSAP/models/WarehouseOrder';
import { validHex } from '@uiw/react-color';
import dayjs from 'dayjs';



/**
 * All menu selectable
 */
enum menuItems{
    PATHS = "PATHS",
    HEATMAP = "HEATMAP",
    LOCATION_CODE = "LOCATION_CODE",
}


export type SapDemoData = {
    rawFiles: AuditRawFileDetails;
    optimizationResult: AnalysisDetails;
    sapOrders: WarehouseOrderData[];
    sapTasks: WarehouseTaskData[];
  };

  
  const _apiSap = new apiSAP();
const DemoHeatmapSAP: React.FC = () => {
    const token = sapDemoConfig.token;
    const mapId = sapDemoConfig.mapId;
    const tenant = sapDemoConfig.tenant;
    const sapWharehouseId = sapDemoConfig.sapWharehouseId;
    
    const dateFrom = dayjs().add(-2, 'month').toDate();
    const dateTo = dayjs().add(2, 'month').toDate();

    const [menuSelected, setMenuSelected] = useState<menuItems>(menuItems.PATHS);
    const [data, setData] = useState<SapDemoData | undefined>(undefined);
    const {
        sdk,
        loading,
        mapReady
    } = useFnoMapSdk(token, tenant, mapId);


    useEffect(() => {
        const apiConfig = getApiConfig(tenant, token);
        const apiAuditRaw = new AuditFile(apiConfig);
        const apiAuditResult = new PickingOptimizationResult(apiConfig);


        Promise.all([
            apiAuditRaw.auditRawFilesList(mapId),
            apiAuditResult.pickingOptimizationResultsList(mapId, {rawFileId: "66978875dd418dab48605456"}),
            _apiSap.getWarehouseOrders(sapWharehouseId, 5000),
            _apiSap.getAllWarehouseTasks(sapDemoConfig.sapWharehouseId, 5000)
        ]).then((result) => {

            const demoData = {
                rawFiles: result[0].data[0], 
                optimizationResult: result[1].data[0],
                sapOrders: result[2],
                sapTasks: result[3]
            } as SapDemoData

            setData(demoData);





            /* 
            const taskByOrder:_.Dictionary<WarehouseTaskData[]> = _.groupBy(result[3], (row) => row.WarehouseOrder);
            const json: string[][] = [];
            _.each(taskByOrder, (tasks, order) => {

                const locationCodes: string[] = [];
                for (let index = 0; index < tasks.length; index++) {
                    const product = tasks[index];
                    locationCodes.push(product.SourceStorageBin);
                    locationCodes.push(product.DestinationStorageBin);
                }

                if(locationCodes.length > 0)
                {   
                    json.push(["start", ...locationCodes, "end"])
                }
                
            })
            console.log("AAAA taskByOrder:", json)
            */

        })

        /*const apiBackoffice = new MapAsset(apiConfig);
        apiBackoffice.assetsList(mapId).then((allAssets) => {
            console.log("AAAA assets:", allAssets)

            const assetsWithoutStartEnd = _.filter(allAssets.data, (asset: AssetDetails) => {
                return asset.locationCode != "start" && asset.locationCode != "end"
            })
            const copy = [...assetsWithoutStartEnd];
            const result:AssetDetails[] = [];

            for (let i = 0; i < __locationCodes.length; i++) {
                // Générer un index aléatoire parmi les éléments restants
                const randomIndex = Math.floor(Math.random() * copy.length);
                // Ajouter l'élément au résultat
                const item = copy[randomIndex];
                item.locationCodes=[__locationCodes[i]]
                result.push(item);
                // Retirer l'élément sélectionné pour éviter les doublons
                copy.splice(randomIndex, 1);
            }

            console.log("AAAA assetsToUpdate:", result)
        });*/

    }, [])


    useEffect(() => {
        if(mapReady && sdk){
            sdk.heatmap.preloadHeatmap(dateFrom, dateTo , ["sap_demo"])
        }
    }, [mapReady, sdk])

    return (<div id="demo-picking-body">
        <Container fluid>
            {<Row id='menu'>
                <Col sm="4" onClick={() => setMenuSelected(menuItems.PATHS)} className={'g-0 item ' + (menuSelected == menuItems.PATHS ? "selected" : "")}><FontAwesomeIcon icon={["fas", "route"]} /> Paths</Col>
                <Col sm="4" onClick={() => setMenuSelected(menuItems.HEATMAP)} className={'g-0 item ' + (menuSelected == menuItems.HEATMAP ? "selected" : "")}><FontAwesomeIcon icon={["fas", "table-cells"]} /> Heatmap</Col>
                <Col sm="4" onClick={() => setMenuSelected(menuItems.LOCATION_CODE)} className={'g-0 item ' + (menuSelected == menuItems.LOCATION_CODE ? "selected" : "")}><FontAwesomeIcon icon={["fas", "barcode"]} /> Locations code</Col>
            </Row>}
        </Container>
        <Container  className='g-0'  fluid style={{height: "100%"}}>
            <Row id='content' className='g-0'>
                <Col id="map-overlay" sm="8">
                    {<MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.blue
                        }}/>}
                </Col>
                <Col sm="4" className='sub-menu'>
                   {sdk && mapReady && menuSelected == menuItems.HEATMAP && <HeatMapPanel sdk={sdk} />}
                   {sdk && mapReady && menuSelected == menuItems.PATHS && <PathPanel sdk={sdk} data={data}/>}
                   {sdk && mapReady && menuSelected == menuItems.LOCATION_CODE && <LocationCodePanel sdk={sdk} data={data}/>}
                </Col>
            </Row>
        </Container>
        {!mapReady && <div id='fullscreen-loading'><Loading text={"Loading ..."} fullScreen fullScreenBackgroundColor='#fff'/></div>}
        </div>)
}

export default DemoHeatmapSAP



const __locationCodes = [
    "S001-01-01",
    "CL-AREA",
    "SF01-03-04",
    "GI-AREA-01",
    "S001-01-02",
    "SF01-01-01",
    "SG01-GENERAL",
    "SF01-03-03",
    "SF01-03-01",
    "SCRAP-AREA",
    "S001-01-03",
    "PROD-SUPPLY",
    "S001-01-04",
    "S001-03-02",
    "S001-03-01",
    "ZRAP-01-01-01",
    "ZRAC.01.01.01",
    "ZRAC.01.01.02",
    "ZRAC.01.01.03",
    "ZRAC.01.01.04",
    "ZRAC.01.01.05",
    "ZRAC.01.02.01",
    "ZRAC.01.02.02",
    "ZRAC.01.02.03",
    "ZRAC.01.02.04",
    "ZRAC.01.02.05",
    "ZRAC.01.03.01",
    "ZRAC.01.03.02",
    "ZRAC.01.03.03",
    "ZRAC.01.03.04",
    "ZRAC.01.03.05",
    "ZRAC.01.04.01",
    "ZRAC.01.04.02",
    "ZRAC.01.04.03",
    "ZRAC.01.04.04",
    "ZRAC.01.04.05",
    "ZRAC.01.05.01",
    "ZRAC.01.05.02",
    "ZRAC.01.05.03",
    "ZRAC.01.05.04",
    "ZRAC.01.05.05",
    "ZRAC.01.06.01",
    "ZRAC.01.06.02",
    "ZRAC.01.06.03",
    "ZRAC.01.06.04",
    "ZRAC.01.06.05",
    "ZRAC.01.07.01",
    "ZRAC.01.07.02",
    "ZRAC.01.07.03",
    "ZRAC.01.07.04",
    "ZRAC.01.07.05",
    "ZRAC.01.08.01",
    "ZRAC.01.08.02",
    "ZRAC.01.08.03",
    "ZRAC.01.08.04",
    "ZRAC.01.08.05",
    "ZPIC.08.01.01",
    "ZPIC.08.02.01",
    "ZPIC.08.03.01",
    "ZPIC.08.04.01",
    "ZRAC.01.09.01",
    "ZRAC.01.09.02",
    "ZRAC.01.09.03",
    "ZRAC.01.09.04",
    "ZRAC.01.09.05",
    "ZRAC.01.10.01",
    "ZRAC.01.10.02",
    "ZRAC.01.10.03",
    "ZRAC.01.10.04",
    "ZRAC.01.10.05",
    "ZRAC.01.11.01",
    "ZRAC.01.11.02",
    "ZRAC.01.11.03",
    "ZRAC.01.11.04",
    "ZRAC.01.11.05",
    "ZRAC.01.12.01",
    "ZRAC.01.12.02",
    "ZRAC.01.12.03",
    "ZRAC.01.12.04",
    "ZRAC.01.12.05",
    "ZRAC.01.13.01",
    "ZRAC.01.13.02",
    "ZRAC.01.13.03",
    "ZRAC.01.13.04",
    "S001-04-01",
    "S001-04-02",
    "S001-04-03",
    "S001-04-04",
    "S001-05-01",
    "S001-05-02",
    "S001-05-03",
    "S001-05-04",
    "S001-06-01",
    "S001-06-02",
    "S001-06-03",
    "S001-06-04",
    "S001-07-01",
    "S001-07-02",
    "S001-07-03",
    "S001-07-04",
    "S001-08-01",
    "S001-08-02",
    "S001-08-03",
    "S001-08-04",
    "S001-09-01",
    "S001-09-02",
    "S001-09-03",
    "S001-09-04",
    "S001-10-01",
    "S001-10-02",
    "S001-10-03",
    "S001-10-04",
    "S001-11-01",
    "S001-11-02",
    "S001-11-03",
    "S001-11-04",
    "S001-12-01",
    "S001-12-02",
    "S001-12-03",
    "S001-12-04",
    "S001-13-01",
    "S001-13-02",
    "S001-13-03",
    "S001-13-04",
    "S001-14-01",
    "S001-14-02",
    "S001-14-03",
    "S001-14-04",
    "S001-15-01",
    "S001-15-02",
    "S001-15-03",
    "S001-15-04",
    "S001-16-01",
    "S001-16-02",
    "S001-16-03",
    "S001-16-04",
    "S001-17-01",
    "S001-17-02",
    "S001-17-03",
    "S001-17-04",
    "S001-18-01",
    "S001-18-02",
    "S001-18-03",
    "S001-18-04",
    "S001-19-01",
    "S001-19-02",
    "S001-19-03",
    "S001-19-04",
    "S001-20-01",
    "S001-20-02",
    "S001-20-03",
    "S001-20-04",
    "S001-21-01",
    "S001-21-02",
    "S001-21-03",
    "S001-21-04",
    "S001-22-01",
    "S001-22-02",
    "S001-22-03",
    "S001-22-04",
    "S001-23-01",
    "S001-23-02",
    "S001-23-03",
    "S001-23-04",
    "S001-24-01",
    "S001-24-02",
    "S001-24-03",
    "S001-24-04",
    "S001-25-01",
    "S001-25-02",
    "S001-25-03",
    "S001-25-04",
    "S001-26-01",
    "S001-26-02",
    "S001-26-03",
    "S001-26-04",
    "S001-27-01",
    "S001-27-02",
    "S001-27-03",
    "S001-27-04",
    "S001-28-01",
    "S001-28-02",
    "S001-28-03",
    "S001-28-04",
    "ZPIC.04.18.01",
    "ZPIC.06.12.01",
    "ZRAP.01.01.01",
    "ZRAP.01.01.03",
    "ZRAP.01.01.04",
    "ZRAP.01.01.05",
    "ZRAP.01.02.01",
    "ZRAP.01.02.02",
    "ZRAP.01.02.03",
    "ZRAP.01.02.04",
    "ZRAP.01.02.05",
    "ZRAP.01.03.01",
    "ZRAP.01.03.03",
    "ZRAP.01.03.05"
]