/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccountInfos {
    /** @format uuid */
    id: string
    /** @minLength 1 */
    email: string
    /** @minLength 1 */
    firstName: string
    /** @minLength 1 */
    lastName: string
    permissionsByMap: Record<
        string,
        {
            map_create?: boolean
            map_update?: boolean
            map_delete?: boolean
            map_read?: boolean
            metadata_create?: boolean
            metadata_update?: boolean
            metadata_delete?: boolean
            metadata_read?: boolean
            token_create?: boolean
            token_update?: boolean
            token_delete?: boolean
            token_read?: boolean
            user_create?: boolean
            user_update?: boolean
            user_delete?: boolean
            user_read?: boolean
            statistics_read?: boolean
            storage_file_list?: boolean
            storage_file_create?: boolean
            storage_file_update?: boolean
            storage_file_delete?: boolean
            picking_optimization_menu?: boolean
            picking_optimization_create?: boolean
            picking_optimization_read?: boolean
            picking_optimization_run?: boolean
            audit_list?: boolean
            audit_edit?: boolean
            audit_raw_data?: boolean
            audit_result_path?: boolean
            audit_result_batching?: boolean
            audit_result_final_path?: boolean
            audit_result_final_batching?: boolean
            audit_heatmap_read?: boolean
            batching_menu?: boolean
            batching_raw_file_list?: boolean
            batching_raw_file_create?: boolean
            batching_raw_file_update?: boolean
            batching_raw_file_delete?: boolean
        }
    >
    accountRoles: string[]
}

export interface AnalysisDetails {
    /** @minLength 1 */
    auditRawFileId: string
    /** @format date-time */
    dateOptimization: string
    customerReference?: string | null
    path: PathAnalysis
    batching: BatchingAnalysis
    final: IntegralOptimization
    batchingPathClient: IntegralOptimization
    id?: string | null
    /** @format int32 */
    version?: number
    /** @format date-time */
    dateCreated?: string
    /** @format date-time */
    dateUpdated?: string
}

export interface ApiErrorsResponse {
    errors?: Record<string, string[]>
}

export interface AssetCreate {
    /** @minLength 1 */
    assetBundleId: string
    /** @minLength 1 */
    floorId: string
    position: Vector3
    rotation: Vector3
    scale: Vector3
    locationCode?: string | null
    locationCodes?: string[] | null
    metadataGroupIds?: string[] | null
    assetTemplateId?: string | null
}

export interface AssetDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    floorId: string
    /** @minLength 1 */
    assetBundleId: string
    position: Vector3
    rotation: Vector3
    scale: Vector3
    metadataGroupIds: string[]
    /** @minLength 1 */
    locationCode: string
    locationCodes: string[]
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    assetTemplateId?: string | null
}

export interface AssetTemplateCreate {
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    assetColor: string
}

export interface AssetTemplateDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    assetColor: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface AssetTemplateUpdate {
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    assetColor: string
    /** @minLength 1 */
    id: string
}

export interface AssetUpdate {
    /** @minLength 1 */
    assetBundleId: string
    /** @minLength 1 */
    floorId: string
    position: Vector3
    rotation: Vector3
    scale: Vector3
    locationCode?: string | null
    locationCodes?: string[] | null
    metadataGroupIds?: string[] | null
    assetTemplateId?: string | null
    /** @minLength 1 */
    id: string
}

export interface AuditRawFileCreate {
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    mapId: string
    /** @format date-time */
    dateFrom: string
    /** @format date-time */
    dateTo: string
    /** @format int32 */
    totalRows?: number
    /** @format int32 */
    totalSupports?: number
    /** @format int32 */
    totalMissions?: number
    columnsBindingData?: ColumnsBindingData
}

export interface AuditRawFileCreateResult {
    /** @minLength 1 */
    id: string
    auditResultId?: string | null
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    name: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    storagePathDataCsv: string
    /** @minLength 1 */
    author: string
    enabled: boolean
    /** @minLength 1 */
    downloadUrlDataCsv: string
    /** @format date-time */
    dateFrom: string
    /** @format date-time */
    dateTo: string
    /** @format int32 */
    totalRows: number
    /** @format int32 */
    totalSupports: number
    /** @format int32 */
    totalMissions: number
    columnsBindingData: ColumnsBindingData
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    temporaryUploadUrlDataCsv?: string | null
}

export interface AuditRawFileDetails {
    /** @minLength 1 */
    id: string
    auditResultId?: string | null
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    name: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    storagePathDataCsv: string
    /** @minLength 1 */
    author: string
    enabled: boolean
    /** @minLength 1 */
    downloadUrlDataCsv: string
    /** @format date-time */
    dateFrom: string
    /** @format date-time */
    dateTo: string
    /** @format int32 */
    totalRows: number
    /** @format int32 */
    totalSupports: number
    /** @format int32 */
    totalMissions: number
    columnsBindingData: ColumnsBindingData
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface BatchingAnalysis {
    original: Record<string, string[]>
    optimized: Record<string, string[]>
    /** @format double */
    totalDistanceOriginal?: number | null
    /** @format double */
    totalDistanceOptimized?: number | null
}

export interface BuildingCreate {
    /** @minLength 1 */
    name: string
    position?: Vector3
    floorIds?: string[] | null
    components?: Record<string, string[]>
}

export interface BuildingDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    mapId: string
    position: Vector3
    floorIds: string[]
    components: Record<string, string[]>
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface BuildingUpdate {
    /** @minLength 1 */
    name: string
    position?: Vector3
    floorIds?: string[] | null
    components?: Record<string, string[]>
    /** @minLength 1 */
    id: string
}

export interface ClientItem {
    /** @format int32 */
    id: number
    /** @minLength 1 */
    tenant: string
    /** @minLength 1 */
    name: string
    roleName?: string | null
    roleId?: string | null
    maps: MapItemViewModel[]
}

export interface ColumnsBindingData {
    /** @format int32 */
    fieldSupport: number
    /** @format int32 */
    fieldMission: number
    /** @format int32 */
    fieldEmplacement: number
    /** @format int32 */
    fieldQuantity: number
    /** @format int32 */
    fieldEan: number
}

export interface FileCreate {
    /** @minLength 1 */
    fileName: string
}

export interface FileDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    storagePath: string
    /** @minLength 1 */
    url: string
    /** @minLength 1 */
    fileName: string
    /** @minLength 1 */
    hash: string
    /** @minLength 1 */
    author: string
    /** @minLength 1 */
    fileType: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface FileDetailsWithUploadUrl {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    storagePath: string
    /** @minLength 1 */
    url: string
    /** @minLength 1 */
    fileName: string
    /** @minLength 1 */
    hash: string
    /** @minLength 1 */
    author: string
    /** @minLength 1 */
    fileType: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    /** @minLength 1 */
    sasToken: string
    /** @minLength 1 */
    urlUpload: string
}

export interface FileUpdate {
    /** @minLength 1 */
    fileName: string
    /** @minLength 1 */
    id: string
}

export interface FloorCreate {
    /** @minLength 1 */
    name: string
    position?: Vector3
    components?: Record<string, string[]>
    entityIds?: string[] | null
    /** @format int32 */
    floorIndex: number
    metadataGroupIds?: string[] | null
}

export interface FloorDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    mapId: string
    position: Vector3
    components: Record<string, string[]>
    metadataGroupIds: string[]
    entityIds: string[]
    /** @format int32 */
    floorIndex: number
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface FloorUpdate {
    /** @minLength 1 */
    name: string
    position?: Vector3
    components?: Record<string, string[]>
    entityIds?: string[] | null
    /** @format int32 */
    floorIndex: number
    metadataGroupIds?: string[] | null
    /** @minLength 1 */
    id: string
}

export interface GenerateUploadLinkResult {
    /** @minLength 1 */
    storagePath: string
    /** @minLength 1 */
    sasToken: string
}

export interface HeatMapPathCreate {
    positions: Vector3[]
    locationCodes: string[]
    /** @format int32 */
    floorIndex: number
    /** @minLength 1 */
    category: string
    userRef?: string | null
    /** @format date-time */
    date?: string | null
}

export interface HeatMapPathDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    mapId: string
    /** @format int32 */
    version: number
    positions: Vector3[]
    locationCodes: string[]
    /** @format int32 */
    floorIndex: number
    /** @minLength 1 */
    category: string
    /** @minLength 1 */
    userRef: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    /** @format date-time */
    date: string
}

export interface HeatMapPointCreate {
    position: Vector2
    /** @format int32 */
    floorIndex: number
    /** @minLength 1 */
    category: string
    userRef?: string | null
    /** @format double */
    value: number
    /** @format date-time */
    date?: string | null
}

export interface HeatMapPointDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    mapId: string
    /** @format int32 */
    version: number
    position: Vector2
    /** @format int32 */
    floorIndex: number
    /** @minLength 1 */
    category: string
    /** @minLength 1 */
    userRef: string
    /** @format double */
    value: number
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    /** @format date-time */
    date: string
}

export interface HomeStatusResult {
    build: string | null
}

export interface IntegralOptimization {
    missions: Record<string, MissionDetails>
}

export interface LabelCreate {
    text?: string | null
    imageId?: string | null
}

export interface LabelDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    text: string
    /** @minLength 1 */
    imageId: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface LabelTemplateCreate {
    /** @minLength 1 */
    textColor: string
    /** @minLength 1 */
    imageColor: string
}

export interface LabelTemplateDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    textColor: string
    /** @minLength 1 */
    imageColor: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface LabelTemplateUpdate {
    /** @minLength 1 */
    textColor: string
    /** @minLength 1 */
    imageColor: string
    /** @minLength 1 */
    id: string
}

export interface LabelUpdate {
    text?: string | null
    imageId?: string | null
    /** @minLength 1 */
    id: string
}

export interface LanguageItem {
    /** @minLength 1 */
    code: string
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    urlFlag: string
}

export interface LoginParams {
    /** @minLength 1 */
    email: string
    /**
     * @format password
     * @minLength 1
     */
    password: string
}

export interface LoginResult {
    token?: string | null
    clientTenants: ClientItem[]
    roles: string[]
}

export interface MapAssetStorageDetails {
    id?: string | null
    assetFolderPath?: string | null
    assetFolderUrl?: string | null
}

export interface MapCreate {
    /** @minLength 1 */
    name: string
    reference?: string | null
    settingId?: string | null
    urlNavmeshWebGl?: string | null
    isUnderMaintenance: boolean
}

export interface MapDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    idShort: number
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    settingId?: string | null
    reference?: string | null
    urlNavmeshWebGl?: string | null
    assetFolderPath?: string | null
    assetFolderUrl?: string | null
    buildingIds: string[]
    isUnderMaintenance: boolean
    /** @format int32 */
    optimizationSettingId?: number | null
    languages: LanguageItem[]
    /** @format int32 */
    totalPolygons: number
    /** @format int32 */
    totalFloors: number
    /** @format int32 */
    totalBuildings: number
    /** @format int32 */
    totalAssets: number
    /** @format int32 */
    totalOffMeshLinks: number
    /** @format int32 */
    totalTemplates: number
    /** @format int32 */
    totalMarkers: number
    /** @format int32 */
    totalMarkerTemplates: number
    /** @format int32 */
    totalNavmeshes: number
    /** @format int32 */
    totalMetadataGroups: number
    /** @format int32 */
    totalAudits: number
    /** @format int32 */
    totalZones: number
    /** @format int32 */
    totalLabels: number
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface MapItemViewModel {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    name: string
}

export interface MapSettingCreate {
    /** @minLength 1 */
    name: string
    userPosition?: UserPositionSetting
}

export interface MapSettingDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    mapId: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    userPosition: UserPositionSetting
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface MapSettingUpdate {
    /** @minLength 1 */
    name: string
    userPosition?: UserPositionSetting
    /** @minLength 1 */
    id: string
}

export interface MapUpdate {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    name: string
    reference?: string | null
    isUnderMaintenance: boolean
}

export interface MarkerCreate {
    /** @deprecated */
    text?: string | null
    /** @deprecated */
    templateMarker?: string | null
    /** @deprecated */
    imageId?: string | null
    gpsPosition?: Vector2
    position: Vector3
    rotation?: Vector3
    metadataGroupIds?: string[] | null
    labelId?: string | null
    labelTemplateId?: string | null
}

export interface MarkerDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /**
     * @deprecated
     * @minLength 1
     */
    text: string
    /**
     * @deprecated
     * @minLength 1
     */
    templateMarker: string
    /**
     * @deprecated
     * @minLength 1
     */
    imageId: string
    labelId?: string | null
    labelTemplateId?: string | null
    gpsPosition: Vector2
    position: Vector3
    rotation?: Vector3
    /** @minLength 1 */
    mapId: string
    metadataGroupIds: string[]
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface MarkerTemplateCreate {
    name?: string | null
    categories?: string[] | null
    textColor?: string | null
    imageColor?: string | null
}

export interface MarkerTemplateDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    categories: string[]
    /** @minLength 1 */
    textColor: string
    /** @minLength 1 */
    imageColor: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface MarkerTemplateUpdate {
    name?: string | null
    categories?: string[] | null
    textColor?: string | null
    imageColor?: string | null
    /** @minLength 1 */
    id: string
}

export interface MarkerUpdate {
    /** @deprecated */
    text?: string | null
    /** @deprecated */
    templateMarker?: string | null
    /** @deprecated */
    imageId?: string | null
    gpsPosition?: Vector2
    position: Vector3
    rotation?: Vector3
    metadataGroupIds?: string[] | null
    labelId?: string | null
    labelTemplateId?: string | null
    /** @minLength 1 */
    id: string
}

export interface MetadataGroupCreate {
    /** @minLength 1 */
    name: string
    data?: Record<string, string>
}

export interface MetadataGroupDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    mapId: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    data: Record<string, string>
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface MetadataGroupUpdate {
    /** @minLength 1 */
    name: string
    data?: Record<string, string>
    /** @minLength 1 */
    id: string
}

export interface MiddlewareApiDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    clientTenant: string
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    baseUrl: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface MissionDetails {
    emplacement: string[]
    supports: string[]
    /** @format double */
    totalDistance: number
    /** @format float */
    fillingRate?: number | null
}

export interface NavmeshCreate {
    /** @minLength 1 */
    name: string
}

export interface NavmeshDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    navmeshUrl: string
    /** @minLength 1 */
    storagePathNavmesh: string
    /** @minLength 1 */
    mapId: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface NavmeshDetailsWithUploadLink {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    navmeshUrl: string
    /** @minLength 1 */
    storagePathNavmesh: string
    /** @minLength 1 */
    mapId: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    uploadData: GenerateUploadLinkResult
}

export interface NavmeshUpdate {
    /** @minLength 1 */
    name: string
    /** @minLength 1 */
    id: string
}

export interface OffMeshLinkCreate {
    start: Vector3
    end: Vector3
    position: Vector3
    /** @format float */
    costOverride: number
    bidirectional: boolean
    activated: boolean
    autoUpdatePositions: boolean
    /** @format int32 */
    navigationArea: number
}

export interface OffMeshLinkDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    start: Vector3
    end: Vector3
    position: Vector3
    /** @format float */
    costOverride: number
    bidirectional: boolean
    activated: boolean
    autoUpdatePositions: boolean
    /** @format int32 */
    navigationArea: number
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface OffMeshLinkUpdate {
    start: Vector3
    end: Vector3
    position: Vector3
    /** @format float */
    costOverride: number
    bidirectional: boolean
    activated: boolean
    autoUpdatePositions: boolean
    /** @format int32 */
    navigationArea: number
    /** @minLength 1 */
    id: string
}

export interface Operation {
    operationType?: OperationType
    path?: string | null
    op?: string | null
    from?: string | null
    value?: any
}

/** @format int32 */
export enum OperationType {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2,
    Value3 = 3,
    Value4 = 4,
    Value5 = 5,
    Value6 = 6
}

export interface OptimizationResult {
    /** @format double */
    gain?: number
    /** @format double */
    oldCost?: number
    /** @format double */
    newCost?: number
    optimizeMatrixLocationCode?: string[] | null
}

export interface PathAnalysis {
    paths: Record<string, PathDetails>
}

export interface PathDetails {
    original: PathMetrics
    optimized: PathMetrics
    /** @format float */
    fillingRate?: number | null
}

export interface PathMetrics {
    emplacements: string[]
    /** @format double */
    totalDistance: number
}

export enum PermissionsEnum {
    MapCreate = 'map_create',
    MapUpdate = 'map_update',
    MapDelete = 'map_delete',
    MapRead = 'map_read',
    MetadataCreate = 'metadata_create',
    MetadataUpdate = 'metadata_update',
    MetadataDelete = 'metadata_delete',
    MetadataRead = 'metadata_read',
    TokenCreate = 'token_create',
    TokenUpdate = 'token_update',
    TokenDelete = 'token_delete',
    TokenRead = 'token_read',
    UserCreate = 'user_create',
    UserUpdate = 'user_update',
    UserDelete = 'user_delete',
    UserRead = 'user_read',
    StatisticsRead = 'statistics_read',
    StorageFileList = 'storage_file_list',
    StorageFileCreate = 'storage_file_create',
    StorageFileUpdate = 'storage_file_update',
    StorageFileDelete = 'storage_file_delete',
    PickingOptimizationMenu = 'picking_optimization_menu',
    PickingOptimizationCreate = 'picking_optimization_create',
    PickingOptimizationRead = 'picking_optimization_read',
    PickingOptimizationRun = 'picking_optimization_run',
    AuditList = 'audit_list',
    AuditEdit = 'audit_edit',
    AuditRawData = 'audit_raw_data',
    AuditResultPath = 'audit_result_path',
    AuditResultBatching = 'audit_result_batching',
    AuditResultFinalPath = 'audit_result_final_path',
    AuditResultFinalBatching = 'audit_result_final_batching',
    AuditHeatmapRead = 'audit_heatmap_read',
    BatchingMenu = 'batching_menu',
    BatchingRawFileList = 'batching_raw_file_list',
    BatchingRawFileCreate = 'batching_raw_file_create',
    BatchingRawFileUpdate = 'batching_raw_file_update',
    BatchingRawFileDelete = 'batching_raw_file_delete'
}

export interface PickingOptimizationPathLogDetails {
    id?: string | null
    /** @format int32 */
    version?: number
    runnerId?: string | null
    runnerName?: string | null
    /** @format double */
    durationMs?: number | null
    orderIds?: string[] | null
    status?: PickingRequestStatus
    result?: PickingOptimizationPathResult
    errorMessage?: string | null
    /** @format date-time */
    dateCreated?: string
    /** @format date-time */
    dateUpdated?: string
}

export interface PickingOptimizationPathLogDetailsPagedListResult {
    /** @format int32 */
    currentPage?: number
    /** @format int32 */
    pageSize?: number
    sortColumn?: string | null
    sortDirection?: string | null
    items?: PickingOptimizationPathLogDetails[] | null
    /** @format int32 */
    totalCount?: number
    /** @format int32 */
    totalPages?: number
}

export interface PickingOptimizationPathResult {
    originalSupport?: Record<string, string[]>
    validSupportWithId?: Record<string, string[]>
    wrongSupportWithMissingEmplacementWithId?: Record<string, string[]>
    emplacementMissingAndNumberUsed?: Record<string, number>
    optimizationDatas?: Record<string, OptimizationResult>
}

export interface PickingOptimizeCallback {
    succeeded?: boolean
    errorCode?: string | null
    errorData?: string | null
    supportsOptimized?: string[][] | null
    supportsWithEmplacementsMissing?: string[][] | null
    emplacementsMissing?: string[] | null
    /** @format date-span */
    durationLoadEmplacementPosition?: string
    /** @format date-span */
    durationLoadMatrix?: string
    /** @format date-span */
    durationOptimizationCompute?: string
}

export interface PickingOptimizeCreate {
    picker?: string | null
    orders?: string | null
}

export interface PickingOptimizeDetails {
    id?: string | null
    status?: string | null
    errorCode?: string | null
    errorDetails?: string | null
    urlMatrix?: string | null
    urlProductPositions?: string | null
    supportsOptimized?: string[][] | null
    supportsWithProductsMissing?: string[][] | null
    emplacementMissing?: string[] | null
    /** @format double */
    durationLoadEmplacementPosition?: number
    /** @format double */
    durationLoadMatrix?: number
    /** @format double */
    durationOptimizationCompute?: number
    /** @format double */
    durationTotal?: number
    picker?: string | null
    /** @format int32 */
    supportsOriginalCount?: number
    /** @format int32 */
    supportsOptimizedCount?: number
    /** @format int32 */
    supportsWithEmplacementsMissingCount?: number
    /** @format int32 */
    emplacementsMissingCount?: number
    /** @format date-time */
    dateCreated?: string
    mapId?: string | null
}

export interface PickingOptimizeGetData {
    urlMatrix?: string | null
    urlEmplacementPositions?: string | null
    supportsOriginal?: string[][] | null
    mapId?: string | null
}

export interface PickingOptimizeRow {
    id?: string | null
    status?: string | null
    errorCode?: string | null
    errorDetails?: string | null
    /** @format double */
    durationLoadEmplacementPosition?: number
    /** @format double */
    durationLoadMatrix?: number
    /** @format double */
    durationOptimizationCompute?: number
    /** @format double */
    durationTotal?: number
    picker?: string | null
    /** @format int32 */
    supportsOriginalCount?: number
    /** @format int32 */
    supportsOptimizedCount?: number
    /** @format int32 */
    supportsWithEmplacementsMissingCount?: number
    /** @format int32 */
    emplacementsMissingCount?: number
    /** @format date-time */
    dateCreated?: string
    mapId?: string | null
}

export interface PickingOptimizeRowPagedListResult {
    /** @format int32 */
    currentPage?: number
    /** @format int32 */
    pageSize?: number
    sortColumn?: string | null
    sortDirection?: string | null
    items?: PickingOptimizeRow[] | null
    /** @format int32 */
    totalCount?: number
    /** @format int32 */
    totalPages?: number
}

export interface PickingOrderCreate {
    originalSupports: string[]
    reference?: string | null
}

export interface PickingOrderDetails {
    id?: string | null
    /** @format int32 */
    version?: number
    reference?: string | null
    originalSupports?: string[] | null
    optimizedSupports?: string[] | null
    processed?: boolean
    optimizationPathLogId?: string | null
    /** @format date-time */
    dateCreated?: string
    /** @format date-time */
    dateUpdated?: string
}

export interface PickingOrderDetailsPagedListResult {
    /** @format int32 */
    currentPage?: number
    /** @format int32 */
    pageSize?: number
    sortColumn?: string | null
    sortDirection?: string | null
    items?: PickingOrderDetails[] | null
    /** @format int32 */
    totalCount?: number
    /** @format int32 */
    totalPages?: number
}

export interface PickingOrderUpdate {
    originalSupports: string[]
    reference?: string | null
    /** @minLength 1 */
    id: string
}

/** @format int32 */
export enum PickingRequestStatus {
    Value0 = 0,
    Value1 = 1,
    Value2 = 2
}

export interface PolygonCreate {
    /** @minLength 1 */
    floorId: string
    /** @minLength 1 */
    category: string
    points?: Vector3[] | null
    holes?: number[] | null
    position: Vector3
    metadataGroupIds?: string[] | null
}

export interface PolygonDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    mapId: string
    /** @minLength 1 */
    floorId: string
    /** @minLength 1 */
    category: string
    points: Vector3[]
    holes: number[]
    position: Vector3
    metadataGroupIds: string[]
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface PolygonUpdate {
    /** @minLength 1 */
    floorId: string
    /** @minLength 1 */
    category: string
    points?: Vector3[] | null
    holes?: number[] | null
    position: Vector3
    metadataGroupIds?: string[] | null
    /** @minLength 1 */
    id: string
}

export interface RoleClientDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    name: string
    permissions: {
        map_create?: boolean
        map_update?: boolean
        map_delete?: boolean
        map_read?: boolean
        metadata_create?: boolean
        metadata_update?: boolean
        metadata_delete?: boolean
        metadata_read?: boolean
        token_create?: boolean
        token_update?: boolean
        token_delete?: boolean
        token_read?: boolean
        user_create?: boolean
        user_update?: boolean
        user_delete?: boolean
        user_read?: boolean
        statistics_read?: boolean
        storage_file_list?: boolean
        storage_file_create?: boolean
        storage_file_update?: boolean
        storage_file_delete?: boolean
        picking_optimization_menu?: boolean
        picking_optimization_create?: boolean
        picking_optimization_read?: boolean
        picking_optimization_run?: boolean
        audit_list?: boolean
        audit_edit?: boolean
        audit_raw_data?: boolean
        audit_result_path?: boolean
        audit_result_batching?: boolean
        audit_result_final_path?: boolean
        audit_result_final_batching?: boolean
        audit_heatmap_read?: boolean
        batching_menu?: boolean
        batching_raw_file_list?: boolean
        batching_raw_file_create?: boolean
        batching_raw_file_update?: boolean
        batching_raw_file_delete?: boolean
    }
}

export interface TemplateCreate {
    category?: string | null
    name?: string | null
    categories?: string[] | null
    groundColor?: string | null
    wallColor?: string | null
    topWallColor?: string | null
    /** @format float */
    groundHeight?: number
    /** @format float */
    wallHeight?: number
    /** @format float */
    wallWidth?: number
}

export interface TemplateDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    categories: string[]
    /** @minLength 1 */
    groundColor: string
    /** @minLength 1 */
    wallColor: string
    /** @minLength 1 */
    topWallColor: string
    /** @format float */
    groundHeight: number
    /** @format float */
    wallHeight: number
    /** @format float */
    wallWidth: number
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface TemplateUpdate {
    category?: string | null
    name?: string | null
    categories?: string[] | null
    groundColor?: string | null
    wallColor?: string | null
    topWallColor?: string | null
    /** @format float */
    groundHeight?: number
    /** @format float */
    wallHeight?: number
    /** @format float */
    wallWidth?: number
    /** @minLength 1 */
    id: string
}

export interface UserPositionSetting {
    position?: Vector3
    floorId?: string | null
}

export interface Vector2 {
    /** @format double */
    x?: number
    /** @format double */
    y?: number
}

export interface Vector3 {
    /** @format double */
    x?: number
    /** @format double */
    y?: number
    /** @format double */
    z?: number
}

export interface ZoneCreate {
    floorId?: string | null
    position?: Vector3
    points?: Vector3[] | null
    /** @minLength 1 */
    name: string
    color?: string | null
    /** @format float */
    groundHeight?: number
    labelId?: string | null
    labelTemplateId?: string | null
}

export interface ZoneDetails {
    /** @minLength 1 */
    id: string
    /** @format int32 */
    version: number
    /** @minLength 1 */
    name: string
    position: Vector3
    points: Vector3[]
    labelPositions: Vector3[]
    /** @minLength 1 */
    color: string
    floorId?: string | null
    /** @format float */
    groundHeight: number
    /** @minLength 1 */
    labelId: string
    /** @minLength 1 */
    labelTemplateId: string
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface ZoneUpdate {
    floorId?: string | null
    position?: Vector3
    points?: Vector3[] | null
    /** @minLength 1 */
    name: string
    color?: string | null
    /** @format float */
    groundHeight?: number
    labelId?: string | null
    labelTemplateId?: string | null
    /** @minLength 1 */
    id: string
}
