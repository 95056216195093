import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userProfilActions } from '../Profil/userProfilSlice';
import { AuditRawFileDetails, AnalysisDetails, HeatMapPointDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import { mapActions } from '../Map/mapSlice';

export interface IAuditStore {
  loadingList: boolean;
  loadingOptimizationResult: boolean;
  loadingHeatmap: boolean;
  loadingForm: boolean;
  data: IRawData | undefined;
  optimizationResult: AnalysisDetails | undefined;
  auditRawFiles: AuditRawFileDetails[];
  auditRawFileSelected: AuditRawFileDetails | undefined;
  heatmapData: IHeatMapAuditData | undefined;
}

/**
 * Raw data of the client
 */
export interface IRawData {
  fields: string[];
  rows: any[];
  delimeter: string;
}


export interface IHeatMapAuditData {
  state: 'loaded' | 'loading' | 'error' | 'waitingLoad';
  scaleOriginal: number[];
  scaleOptimized: number[];
}

const initialState: IAuditStore = {
  loadingList: false,
  loadingHeatmap: false,
  loadingOptimizationResult: false,
  loadingForm: false,
  data: undefined,
  auditRawFiles: [],
  optimizationResult: undefined,
  auditRawFileSelected: undefined,
  heatmapData: undefined
};

// Create a slice for the audit
const auditSlice = createSlice({
  name: 'audit',
  initialState, // Initial state defined elsewhere
  reducers: {
    // Reducer to set loading state for raw file
    setLoadingList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Reducer to set loading state for optimization result
    setLoadingOptimizationResult: (state, action: PayloadAction<boolean>) => {
      state.loadingOptimizationResult = action.payload;
    },
    // Set the loadingForm state
    setLoadingFileRawForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
    setLoadingHeatmap: (state, action: PayloadAction<boolean>) => {
      state.loadingHeatmap = action.payload;
    },
    // Reducer to set audit data
    setAuditData: (state, action: PayloadAction<IRawData | undefined>) => {
      state.data = action.payload;
    },
    setHeatmapData: (state, action: PayloadAction<IHeatMapAuditData | undefined>) => {
      state.heatmapData = action.payload;
    },
    // Reducer to set optimization results
    setOptimizationResults: (state, action: PayloadAction<AnalysisDetails | undefined>) => {
      state.optimizationResult = action.payload;
    },
    // Reducer to set audit raw files
    setRawFiles: (state, action: PayloadAction<AuditRawFileDetails[]>) => {
      state.auditRawFiles = action.payload;
    },
    // Reducer to set selected audit raw file
    setAuditRawFileSelected: (state, action: PayloadAction<AuditRawFileDetails | undefined>) => {
      state.auditRawFileSelected = action.payload;
    },
    // Update a specific asset in the state
    updateRawFile: (state, action: PayloadAction<AuditRawFileDetails>) => {
      state.auditRawFiles = state.auditRawFiles.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
     // Add a new file to the state
     addRawFile: (state, action: PayloadAction<AuditRawFileDetails>) => {
      state.auditRawFiles.push(action.payload);
    },
    // remove an asset
    deleteRawFile: (state, action: PayloadAction<string>) => {
      state.auditRawFiles = state.auditRawFiles.filter(m => m.id !== action.payload)
    },
  },
  extraReducers: (builder) => {
    // Reset audit data on user logout
    builder.addCase(userProfilActions.setLogout, (state) => {
      state.data = undefined;
      state.loadingList = false;
      state.loadingForm = false;
      state.auditRawFiles = [];
      state.auditRawFileSelected = undefined;
      state.optimizationResult = undefined;
      state.heatmapData = undefined;
    });
    // Reset audit data when map changes
    builder.addCase(mapActions.setCurrentMap, (state) => {
      state.data = undefined;
      state.loadingList = false;
      state.loadingForm = false;
      state.auditRawFiles = [];
      state.auditRawFileSelected = undefined;
      state.optimizationResult = undefined;
      state.heatmapData = undefined;
    });
  }
});

// Export actions and reducer from auditSlice
export const { actions: auditActions, reducer: auditReducer } = auditSlice;