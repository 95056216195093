import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getCurrentTenant from 'backend/utils/getCurrentTenant';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import Loading from 'components/Loading';
import PageHeader from 'components/PageHeader';
import MapRender from 'components/mapControls/MapRender';
import useFnoMapSdk from 'hooks/useFnoMapSdk';
import React, { useEffect } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import appColors from 'resources/appColors';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';

const MapEditor: React.FC = () => {
   const { t } = useTranslation(nameOf({MapEditor}), { useSuspense: false});
   const currentMap = useAppSelector(state => state.map.currentMap);
   const {
    sdk,
    loading,
    mapReady
} = useFnoMapSdk(getCurrentUserToken(), getCurrentTenant()?.tenant ?? "", currentMap?.id ?? "");

   const dispatch = useAppDispatch();

   useEffect(() => {
   }, [])

   return (
        <div id="statistic-picking-optimization-path">
            {<PageHeader title={t('Editeur de carte')}/>}
            <Row style={{height: "100%"}}>
                <Col  sm="8">
                    <MapRender 
                        sdk={sdk} 
                        mapReady={mapReady}
                        assetToolipConfig={{
                            highlightColor: appColors.orange
                        }}/>
                    TODO A terminer
                </Col>
                <Col id="col-table-container" sm="4">
                    {mapReady && !loading && <div>
                        <h4>
                            <Button size='sm' variant='link' title={t('Centrer caméra')} className='ms-1 float-end' onClick={() => sdk?.camera.resetCamera()}>
                                <FontAwesomeIcon icon={["fas", "bullseye"]} />
                            </Button>
                        </h4>
                        <div>
                            {sdk && <>

                            </>}
                        </div>
                    </div>}
                    {!mapReady && <div className='loading-map-panel'><Loading text={t("Chargement des données")}/></div>}
                </Col>
            </Row>
        </div>
    )
}
export default MapEditor