import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import nameOf from 'utility/nameOf';
import { useAppDispatch, useAppSelector } from 'store/store'
import Loading from 'components/Loading';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Switch from "react-switch";
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import { adminMiddlewareActions } from 'store/reducers/Admin/RealtimeMiddleware/adminMiddlewareSlice';
import { Badge, Button, Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditJsonRaw from 'components/Admin/EditJsonRaw';
import useSignalRHub from 'hooks/useSignalRHub';
import { SpinnerInfinity } from 'spinners-react';
import ButtonFno from 'components/inputs/ButtonFno';
import StatisticCard from 'components/StatisticCard';
import { MiddlewareApiCreate, MiddlewareApiDetails } from 'backend/ApiAdminDefinition/data-contracts';
import { FormikHelpers } from 'formik';
import MiddlewareForm, { IMiddlewareFormInputs } from 'components/Admin/forms/MiddlewareForm';
import { createMiddlewareThunk } from 'store/reducers/Admin/RealtimeMiddleware/thrunks/createMiddlewareThunk';
import { toast } from 'react-toastify';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { deleteMiddlewareThrunk } from 'store/reducers/Admin/RealtimeMiddleware/thrunks/deleteMiddlewareThrunk';
import DeleteConfirmForm from 'components/DeleteConfirmForm';
import { reconnectRealtimeMiddlewaresThrunk } from 'store/reducers/Admin/RealtimeMiddleware/thrunks/reconnectRealtimeMiddlewaresThrunk';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import copyToClipboard from 'utility/copyToClipboard';
import IConnectedMiddlewareModel from 'models/Admin/IConnectedMiddlewareModel';
import HelperTooltip from 'components/HelperTooltip';

const AdminMiddlewareApi: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminMiddlewareApi}), { useSuspense: false});
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const allClients = useAppSelector(state => state.adminClient.allClients);


    const {
        middlewares,
        middlewareConnected,
        loadingForm,
        loadingList
    }= useAppSelector(state => state.adminRealtimeMiddleware);

    const [realtimeEnable, setRealtimeEnable] = useState(true);

    const [showCreateForm, setShowCreateForm] = useState(false);

    const [selectedRow, setSelectedRow] = useState<MiddlewareApiDetails | undefined>();
    const [deleteRow, setDeleteRow] = useState<MiddlewareApiDetails | undefined>();

    const adminHub = useSignalRHub(process.env.REACT_APP_API_PICKING_URL + "/live/picking/middleware", {
        onConnected: (hub) => {
                hub.invoke("GetOnlineMiddleware").then((middlewares: IConnectedMiddlewareModel[]) => {
                    console.log("GetOnlineMiddleware", middlewares); 
                    dispatch(adminMiddlewareActions.setConnectedMiddlewares(middlewares));
                })

                hub.on("MiddlewareConnected", (middleware: IConnectedMiddlewareModel) => { 
                    console.log("MiddlewareConnected !! ", middleware); 
                    dispatch(adminMiddlewareActions.addOrUpdateConnectedMiddleware(middleware))
                });

                hub.on("MiddlewareDisconnected", (middleware: IConnectedMiddlewareModel) => { 
                    console.log("MiddlewareDisconnected !! ", middleware); 
                    dispatch(adminMiddlewareActions.removeConnectedMiddleware(middleware))
                });

                hub.on("MiddlewareUpdated", (middleware: IConnectedMiddlewareModel) => { 
                    console.log("MiddlewareUpdated !! ", middleware); 
                    dispatch(adminMiddlewareActions.addOrUpdateConnectedMiddleware(middleware))
                });
                
        },
        onDisconnected: (err) => {
            console.log("onDisconnected !", err); 
            setRealtimeEnable(false);},
        onError: (err) => {console.log("onError !", err); setRealtimeEnable(false);},
        enabled: realtimeEnable,
        automaticReconnect: true,
        httpTransportTypeOrOptions: {accessTokenFactory: getCurrentUserToken}
      })



    useEffect(() => {
        document.title = t('[Admin] Middleware connectés');
        return () => {
            setRealtimeEnable(false)
        }
    }, [])


    const onSubmit = async (values: IMiddlewareFormInputs, {setFieldError}:FormikHelpers<IMiddlewareFormInputs>) => {

        dispatch(createMiddlewareThunk(values as MiddlewareApiCreate,{
            onSuccess: (marker) => {
                setShowCreateForm(false)
                toast.success(t("Le middleware a bien été créé"))
            },
            onError: (error) => {
                parseApiErrorToast(error);
            }
        }))
    };

    const onDelete = (middleware: MiddlewareApiDetails) => {
        
        dispatch(deleteMiddlewareThrunk(middleware.id,{
            onSuccess: (success) => {
                toast.success(t("Le middleware a bien été supprimé"))
                setDeleteRow(undefined);
            },
            onError: (error) => {
                parseApiErrorToast(error);
            }
        }))
        
    }
    

    const columns: TableColumn<MiddlewareApiDetails>[] = [
        {
            id:'online',
            name: t('En ligne'),
            sortable: true,
            cell: row => {
                const connected = middlewareConnected.find(m => m.id == row.id);

                let btnReconnect = <></>
                if(!connected && realtimeEnable)
                {
                    btnReconnect = <ButtonFno className='ms-1' sm onClick={() => dispatch(reconnectRealtimeMiddlewaresThrunk(row.id))}><FontAwesomeIcon icon={faSyncAlt} /></ButtonFno>
                }

                let onlineBadge = <Badge bg={!connected ? "danger": "success"}> {!connected ? "Non": "Oui"}</Badge>;
                if(!realtimeEnable)
                    onlineBadge = <Badge bg={"secondary"}> {"?"}</Badge>;

                return <>
                    {onlineBadge}
                    {btnReconnect}
                </>
            },
        },
        {
            id:'connectionPool',
            name: t('Db connections'),
            sortable: true,
            cell: row => {
                const connected = middlewareConnected.find(m => m.id == row.id);
                const nbConnections= connected?.databaseConnectionPoolSize ?? 0;
                let badge = <Badge bg={"success"}> {nbConnections}</Badge>;
                if(nbConnections > 60)
                    badge = <Badge bg={"warning"}> {nbConnections}</Badge>;
                if(nbConnections > 80)
                    badge = <Badge bg={"danger"}> {nbConnections}</Badge>;

                return <>{badge}</>
            },
        },
        {
            id:'runnersInCache',
            name: t('Runners en cache'),
            sortable: true,
            selector: row => {
                const connected = middlewareConnected.find(m => m.id == row.id);
                return connected?.runnersInCache.length ?? 0;
            },
            cell: row => {
                const connected = middlewareConnected.find(m => m.id == row.id);

                const nbRunners= connected?.runnersInCache.length ?? 0;
                let badge = <Badge bg={"success"}> {nbRunners}</Badge>;
                if(nbRunners == 0)
                    badge = <Badge bg={"warning"}> {nbRunners}</Badge>;

                return <>
                    {badge}
                </>
            },
        },
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name,
        },
        {
            id:'client',
            name: t('Client'),
            sortable: true,
            selector: row => row.clientTenant,
        },
        {
            id:'url',
            name: t('Url'),
            sortable: true,
            selector: row => row.baseUrl,
            cell:(row, index, column, id) => {

                return <>
                    <a className='me-2' href={`${row.baseUrl}`} target='_blank'>{row.baseUrl}</a>
                    <Button size="sm" variant='link' onClick={() => copyToClipboard(row.baseUrl, "Adresse copiée")} title={t("Copier l'adresse dans le presse papier")}><FontAwesomeIcon icon={['fas', 'copy']} size="sm"/></Button>
                </>
            }
        },
        /*{
            id:'requestInProgress',
            name: t('Nb requêtes en cours'),
            sortable: true,
            cell: row => {
                const connected = middlewareConnected.find(m => m.tenant == row.clientTenant &&  m.url == row.baseUrl);

                if(connected)
                {
                    const requestCount = connected.requests.filter(r => r.status == RequestStatus.Pending).length
                    return <Badge bg={requestCount > 0 ? "danger": "primary"}>{requestCount}</Badge>
                }
                else
                {
                    return "";
                }

            },
        },*/
        {
            name:<div>{t('Actions')}</div>,
            center:true,
            width:"150px",
            cell: (row, index, column, id) =>{
                return <div className='d-flex'>
                    <Button size='sm' variant='success' className='me-1' onClick={() => setSelectedRow(row)}>
                        <FontAwesomeIcon icon={["fas", "eye"]} />
                    </Button>

                    <Button size='sm' variant='danger' className='me-1' onClick={() => setDeleteRow(row)}>
                        <FontAwesomeIcon icon={["fas", "trash"]} />
                    </Button>
                </div>
            }
        }
    ];
    
    let countOffline = middlewares.length - middlewareConnected.length
    if(countOffline <  0) countOffline = 0;
    return (
        <div>
             <PageHeader title={t("Logs temps réel")} admin/>

            <Row>
                <Col><StatisticCard color='blue' title={t("Total Middlewares")} icon='server' value={middlewares.length}/></Col>
                <Col>
                    <StatisticCard color={countOffline > 0 ? 'red' : 'green'} title={t("Middlewares offline")} icon={countOffline > 0 ? 'circle-exclamation' : 'circle-check'} value={countOffline}/>
                </Col>
            </Row>


            <div className='table-component'>
                <DataTable 
                    columns={columns} 
                    data={middlewares} 
                    defaultSortAsc={false} 
                    progressComponent={<Loading text={t("Chargement")}/>}
                    defaultSortFieldId={'dateRegister'} 
                    striped={true}
                    subHeader={true}
                    highlightOnHover={true}
                    noDataComponent={<div className='w-100 text-center p-5'>
                        {!realtimeEnable ? t("Temps réel désactivé") : t("Aucun middleware connecté")}
                        {!realtimeEnable && <div className='mt-3'><ButtonFno onClick={() => setRealtimeEnable(true)}>{t("Activer le temps réel")}</ButtonFno></div>}
                        </div>}
                    subHeaderComponent={<> 
                        <h5 className='text-uppercase'> {t("Les logs")} </h5>
                        <div className='search-panel'>
                            <span className='me-2'>
                                {realtimeEnable && <SpinnerInfinity size={50} thickness={180} speed={98} color="rgba(62, 172, 57, 1)" secondaryColor="rgba(172, 102, 57, 0.31)" />}
                            </span>
                            <span className='me-2'>  
                                <Switch checked={realtimeEnable} onChange={(enabled) => setRealtimeEnable(enabled)}/>
                            </span>
                            {/*<input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>*/}
                            <ButtonFno sm className='me-2' onClick={() => setShowCreateForm(true)}><FontAwesomeIcon className='me-2' icon={["fas", "plus"]} /> {t("Middleware")}</ButtonFno>
                        </div>
                        </>}
                    sortServer={false}/>
            </div>

            {selectedRow && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedRow(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedRow.baseUrl}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditJsonRaw onCancel={() => setSelectedRow(undefined)} value={selectedRow}/>
                </Modal.Body>
            </Modal>}

            {showCreateForm && <Modal dialogClassName='modal-fno' show={true} onHide={() => setShowCreateForm(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Créer un middleware")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MiddlewareForm loading={false} onCancel={() => setShowCreateForm(false)} onSubmit={onSubmit}/>
                </Modal.Body>
            </Modal>}

            {deleteRow && <Modal dialogClassName='modal-fno' show={true} onHide={() => setDeleteRow(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Supprimer le middleware")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DeleteConfirmForm onCancel={() => setDeleteRow(undefined)} 
                                        onSubmit={() => onDelete(deleteRow)} 
                                        text={t("Etes vous sûre de vouloir supprimer ce middleware ?")}
                                        loading={loadingForm}/>
                </Modal.Body>
            </Modal>}
        </div>
    )
}

export default AdminMiddlewareApi
