import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import { adminClientMapAssetTemplateActions } from "../adminClientMapAssetTemplateSlice";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Delete a AssetTemplate
 * @returns 
 */
export const deleteAssetTemplateThrunks = (AssetTemplateId: string, callbacks: ThrunkApiCallBacks<boolean>): AppThunk => {
    return async (dispatch, getState) => {
       try{

            dispatch(adminClientMapAssetTemplateActions.setLoadingAssetTemplateForm(true));
            await apiBackofficeDefinition.assetTemplate.assetTemplateDelete(AssetTemplateId)
                .then((result) => {
                    dispatch(adminClientMapAssetTemplateActions.deleteAssetTemplate(AssetTemplateId))
                    callbacks.onSuccess(true);
                })
                .catch((apiResponse: apiErrorModel) => {
                    callbacks.onError(apiResponse);
                })
                .finally(() => dispatch(adminClientMapAssetTemplateActions.setLoadingAssetTemplateForm(false)));
        }
        catch(error: any)
        {
            if(!error.status || error.status != 401)
                toast.error(i18next.t("Erreur lors de la suppression du AssetTemplate"))
        }
    }
}