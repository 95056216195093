import GradientLegend from 'components/GradientLegend';
import Loading from 'components/Loading';
import dayjs from 'dayjs';
import Sdk from 'fno-sdk';
import { DisplayPrelodedHeatmapResponse } from 'fno-sdk/build/models/unityEventModels/DisplayPrelodedHeatmapResponse';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store/store';
import nameOf from 'utility/nameOf';

type Props = {
    sdk: Sdk;
  };


const HeatMapPanel: React.FC<Props> = ({ sdk}) => {
   const { t } = useTranslation(nameOf({HeatMapPanel}), { useSuspense: false});
   const [loading, setLoading] = useState(false);

   const [heatmapData, setHeatmapdata] = useState<DisplayPrelodedHeatmapResponse | undefined>(undefined);
   const dispatch = useAppDispatch();

   useEffect(() => {
    setLoading(true);
    setHeatmapdata(undefined);
    sdk.heatmap.displayPrelodedHeatmap("sap_demo").then((data) => {
        setHeatmapdata(data);
        setLoading(false);
    })

    return () => {
        //sdk.heatmap.displayHeatmap(dayjs().toDate(), dayjs().toDate(), "Optimized")
        sdk.heatmap.hideHeatmap();
    }
   }, [])

   return (
       <div>
           {loading && <Loading text='Loading heatmap ...'/>}
           {!loading && <div>
                <GradientLegend colors={heatmapData?.gradients.map(m => m.htmlColor) ?? []} />
            </div>}
       </div>
   )
}
export default HeatMapPanel