import { apiSAP } from 'backend/apiSAP/apiSap';
import WarehouseOrderData from 'backend/apiSAP/models/WarehouseOrder';
import Loading from 'components/Loading';
import Sdk from 'fno-sdk';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import sapDemoConfig from '../sapDemoConfig';
import WarehouseTaskData from 'backend/apiSAP/models/WarehouseTaskData';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copyToClipboard from 'utility/copyToClipboard';
import appColors from 'resources/appColors';



type Props = {
    sdk: Sdk;
    sapOrder: WarehouseOrderData;
    sapTasks: WarehouseTaskData[]
  };


const SapOrderProductsTable: React.FC<Props> = ({sdk, sapOrder, sapTasks}) => {
   const { t } = useTranslation(nameOf({SapOrderProductsTable}), { useSuspense: false});
   const sapWharehouseId = sapDemoConfig.sapWharehouseId;
   const [loading, setLoading] = useState<boolean>(false);

   useEffect(() => {
    return () => {
        sdk.pathfinding.clearItineraries();
    }
   }, [])



   useEffect(() => {

        const locationCodes: string[] = [];
        for (let index = 0; index < sapTasks.length; index++) {
            const product = sapTasks[index];
            locationCodes.push(product.SourceStorageBin);
            locationCodes.push(product.DestinationStorageBin);
        }
        if(locationCodes.length > 0)
        {
            sdk.pathfinding.setItineraryToAssetsByLocationCode(["start", ...locationCodes, "end"], appColors.blue);
        }
        
    }, [sapTasks])


   return (
       <div>
           {loading && <Loading text='Loading products ...'/>}
           {!loading && <Table striped bordered hover className={'order-list-item '}>
                    <thead>
                        <tr>
                            
                            <th className='initial'>Product</th>
                            <th className='initial'>Qte</th>
                            <th className='initial'>Source</th>
                            <th className='initial'>Destination</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sapTasks.map((item, i) => {
                            return <tr key={i} >
                                <td className='initial'>{item.Product}</td>
                                <td className='initial'>{item.ActualQuantityInBaseUnit} {item.BaseUnit}</td>
                                <td className='initial'>{item.SourceStorageBin} ({item.SourceStorageType})</td>
                                <td className='initial'>{item.DestinationStorageBin} ({item.DestinationStorageType})</td>
                            </tr>
                        })}
                    </tbody>
                    <tfoot>
                    </tfoot>
                </Table>}
       </div>
   )
}
export default SapOrderProductsTable