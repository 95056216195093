import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { TableColumn } from 'react-data-table-component';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { useAppDispatch, useAppSelector } from 'store/store';
import { loadAssetTemplatesThunks } from 'store/reducers/Admin/ClientMapAssetTemplate/thrunks/loadAssetTemplatesThunks';
import { AssetTemplateDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { createAssetTemplateThunk } from 'store/reducers/Admin/ClientMapAssetTemplate/thrunks/createAssetTemplateThunk';
import { updateAssetTemplateThunk } from 'store/reducers/Admin/ClientMapAssetTemplate/thrunks/updateAssetTemplateThunk';
import { deleteAssetTemplateThrunks } from 'store/reducers/Admin/ClientMapAssetTemplate/thrunks/deleteAssetTemplateThrunks';
import ColorPicker from 'components/inputs/ColorPicker';

const AdminClientMapAssetTemplates: React.FC = () => {
    const { t } = useTranslation(nameOf({AdminClientMapAssetTemplates}), { useSuspense: false});
    const dispatch = useAppDispatch();
    
    const {
        AssetTemplates,
        loadingList,
        loadingForm
    } = useAppSelector(state => state.adminMapAssetTemplate)
    const { clientId } = useParams();


    useEffect(() => {
        document.title = t('[Admin] Asset templates du client');
        if(clientId)
        {
            dispatch(loadAssetTemplatesThunks());
        }
    }, [clientId])


    const onCreate = (AssetTemplate: AssetTemplateDetails, closeModal: () => void) => {
        if(clientId)
        {
            dispatch(createAssetTemplateThunk(AssetTemplate,{
                onSuccess: (asset) => {
                    closeModal();
                    toast.success(t("Le Asset template a bien été créé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
        }
    }
    
    const onUpdate = (AssetTemplate: AssetTemplateDetails, closeModal?: () => void) => {
        if(clientId)
        {
            dispatch(updateAssetTemplateThunk(AssetTemplate,{
                onSuccess: (AssetTemplate) => {
                    if(closeModal)closeModal();
                    toast.success(t("Le Asset template a bien été modifié"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))

        }
    }

    const onDelete = (AssetTemplate: AssetTemplateDetails, closeModal: () => void) => {
        if(clientId)
        {
            dispatch(deleteAssetTemplateThrunks(AssetTemplate.id,{
                onSuccess: (success) => {
                    closeModal();
                    toast.success(t("Le Asset template a bien été supprimé"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))

        }
    }

    const columns: TableColumn<AssetTemplateDetails>[] = [
        {
            id:'name',
            name: t('Nom'),
            sortable: true,
            selector: row => row.name
        },
        {
            id:'assetColor',
            name: t("Couleur de l'asset"),
            sortable: true,
            selector: row => row.assetColor,
            cell: (row, index, column, id) =>{
                return  <ColorPicker
                value={row.assetColor}
                onChange={(color) => onUpdate({... row, assetColor: color})}
            />;
            }
        },
        {
            id:'version',
            name: t('Version'),
            center:true,
            selector: row => row.version,
        }
    ];


    return <TableWithCrudComponent 
                    entities={AssetTemplates} 
                    columns={columns}
                    loadingForm={loadingForm}
                    loadingList={loadingList}
                    onDelete={onDelete}
                    onUpdate={onUpdate}
                    onCreate={onCreate}
                    translations={{
                        tableTitle: t("Asset templates du client"),
                        noEntityText: t("Aucun asset template disponible"),
                        deleteText: (entity) => (t("Voulez-vous supprimer l'asset template ?")),
                        deleteTitle: (entity) => (t("Supprimer l'asset template ") + entity.id),
                        updateText: (entity) => (t("Modifier l'asset template : ") + entity.id),
                        createButtonText: t("Ajouter un asset template"),
                        createTitle: t("Ajouter un asset template"),
                    }}
                    fieldSearchable={(entity) => [
                        entity.id,
                        entity.name,
                        entity.assetColor
                    ]}
                    initialValueCreateForm={{
                        assetColor: "",
                        name: ""

                    } as AssetTemplateDetails}
                    />
}

export default AdminClientMapAssetTemplates
