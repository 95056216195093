import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import { useAppDispatch } from 'store/store';
import Sdk, { Asset } from 'fno-sdk';
import WarehouseTaskData from 'backend/apiSAP/models/WarehouseTaskData';
import { SapDemoData } from '../DemoHeatmapSAP';
import _ from 'lodash';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


type Props = {
    sdk: Sdk;
    data: SapDemoData | undefined;
  };

const LocationCodePanel: React.FC<Props> = ({sdk, data}) => {
   const { t } = useTranslation(nameOf({LocationCodePanel}), { useSuspense: false});
   const dispatch = useAppDispatch();
   const _assetHovered = useRef<Asset | undefined>();
   const [assetHovered, setAssetHovered] = useState<Asset | undefined>();
   
    useEffect(() => {

        const sdkTag = "LocationCodePanel";

        sdk.asset.registerOnHoverAsset((asset) => {
            _assetHovered.current = asset.asset as unknown as Asset;
            setAssetHovered(asset.asset as unknown as Asset)
        }, sdkTag);

        sdk.asset.registerOnHoverLeaveAsset((asset) => {
            _assetHovered.current = undefined;
            setAssetHovered(undefined)
        }, sdkTag);

        return () => {
            sdk.asset.clearHighlights();
            sdk.asset.unRegisterOnHoverAsset(sdkTag);
            sdk.asset.unRegisterOnHoverLeaveAsset(sdkTag);
        }
    }, [])


    useEffect(() => {
        console.log("asset hovered", _assetHovered.current)
    }, [_assetHovered.current])


   useEffect(() => {
    if(data)
    {
        
        const locationCodes: string[] = [];
        for (let index = 0; index < data.sapTasks.length; index++) {
            const product = data.sapTasks[index];
            locationCodes.push(product.SourceStorageBin);
            locationCodes.push(product.DestinationStorageBin);
        }
        const locationCounts = _.countBy(locationCodes);



        // ---------

        // Trier les valeurs pour déterminer les seuils des 33%
        const sortedValues = Object.values(locationCounts).sort((a, b) => a - b);
        const thresholds = [
            sortedValues[Math.floor(sortedValues.length * 0.33)], // Seuil pour les 33% les plus faibles
            sortedValues[Math.floor(sortedValues.length * 0.66)], // Seuil pour les 33% intermédiaires
        ];

        // Assigner une couleur en fonction des seuils
        const assignColor = (count: number): string => {
            /*if (count <= thresholds[0]) {
                return "#21d8de"; // 33% les plus faibles
            } else if (count <= thresholds[1]) {
                return "#de9c21"; // 33% intermédiaires
            } else {
                return "#de2121"; // 33% les plus hautes
            }*/

                if (count <= 2) {
                    return "#21d8de"; // 33% les plus faibles
                } else if (count <= 5) {
                    return "#de9c21"; // 33% intermédiaires
                } else {
                    return "#de2121"; // 33% les plus hautes
                }
        };

        // Associer chaque locationCode à sa couleur
        const locationColors = _.mapValues(locationCounts, assignColor);

        // Grouper les locationCodes par couleur
        const groupedByColor = _.reduce(locationColors, (acc: Record<string, string[]>, color, location) => {
            acc[color] = acc[color] || [];
            acc[color].push(location);
            return acc;
        }, {});

        // ----------------

        sdk.asset.getAllWithLocationCode().then((assets) => {
            _.each(groupedByColor, (locationCodes, color) => {
                const assetFounds = assets.filter(m => m.locationCodes.some(l => locationCodes.includes(l)));
                sdk.asset.setHighlightAssetByIds(assetFounds.map(m => m.id), true, color);
            })
        })
    }
   }, [data])

   if(!assetHovered)
   {
    return <h3 className='text-center'>{"Hover cursor over an asset for getting details"}</h3>
   }


   const tasks = data?.sapTasks.filter(m => assetHovered?.locationCodes.some(l => m.DestinationStorageBin == l || m.SourceStorageBin == l));
   const ordersRef = _.uniq(tasks?.map(m => m.WarehouseOrder))
   const orders = data?.sapOrders.filter(m => ordersRef.some(n => n == m.WarehouseOrder));
   return (
       <div>
           {assetHovered && <div>
                <h3 className='text-center'>{_.join(_.uniq(assetHovered.locationCodes), " - ")}</h3>
                <div>{"Nb Orders use this location: " + orders?.length}</div>
                <div>
                    {orders?.map(m => <Badge className='ms-2' bg="primary"><FontAwesomeIcon icon={["fas", "tag"]} /> {m.WarehouseOrder}</Badge>)}
                </div>

            </div>}
       </div>
   )
}
export default LocationCodePanel