export const ROUTES = {
    LOGIN: '/login',

    DASHBOARD: '/dashboard',
    CATALOG: '/catalog',
    STATISTICS: '/statistics/dashboard',
    
    // Picking pages
    PICKING_DASHBOARD: '/picking/dashboard',
    PICKING_ORDERS: '/picking/orders',
    PICKING_REQUESTS: '/picking/requests',
    PICKING_STATISTICS: '/picking/statistics',
    PICKING_RAW_DATA: '/picking/data',
    PICKING_PATH_COMPARE: '/picking/paths',

    // Batching pages
    BATCHING_DASHBOARD: '/batching/dashboard',
    BATCHING_STATISTICS: '/batching/statistics',

    MANAGE_USERS: '/users',
    MANAGE_TOKENS: '/tokens',
    //SAP_ORDERS_LIST: '/sap/orders', TODO: a supprimer si plus utilisé pour démo

    // Audit pages
    AUDIT_DASHBOARD: '/audit/dashboard',
    AUDIT_PATH_COMPARE: '/audit/paths',
    AUDIT_RAW_DATA: '/audit/data',
    AUDIT_BATCHING: '/audit/batching',
    AUDIT_FINAL_RESULT: '/audit/final/result',
    AUDIT_FINAL_PATH_CLIENT_RESULT: '/audit/final/paths',
    AUDIT_HEATMAP_COMPARE: '/audit/heatmap/compare',
    
    // Map editor pages
    MAP_EDITOR: '/map/editor',


    // Demo pages
    DEMO:{
        TIMCODE:{
            TAG_MANAGER: "/demo/timcode/tagmanager",
            BATCHING_SUPER_U: "/demo/timcode/batching/superu",
            BATCHING_TIMCODE: "/demo/timcode/batching/timcode",
            BATCHING_RESULT: "/demo/timcode/batching/result",
        },
        IVANTI:{
            HEATMAP: "/demo/ivanti/heatmap"
        }
    },


    ADMIN: {
        DASHBOARD: '/admin/dashboard',
        CLIENTS: '/admin/clients',
        USERS: '/admin/users',
        USER: (userId: string | undefined = undefined) => {
            const baseClientUrl ="/admin/user";
            return {
                BASE_PATH: baseClientUrl,
                DETAILS: `${baseClientUrl}/${userId}`,
                CREATE: `${baseClientUrl}/new`,
            }
        },
        TRANSLATIONS: '/admin/translations',
        //REALTIME_LOGS: '/admin/realtime/logs',
        REALTIME: {
            INDEX: '/admin/realtime/dashboard',
            MAP_CHANGES: '/admin/realtime/map/changes',
            CONNECTED_USERS: '/admin/realtime/connected/users',
        },
        PICKING:{
            INDEX: '/admin/picking/dashboard',
            MIDDLEWARES: '/admin/picking/middlewares',
            RUNNERS:  `/admin/picking/runner`,
            CONNECTED_MIDDLEWARES: '/admin/realtime/connected/middlewares'
        },
        CLIENT_CREATE: '/admin/new/client',
        CLIENT: (clientId: string | undefined = undefined) => {
            const baseClientUrl ="/admin/client";
            return {
                BASE_PATH: baseClientUrl,
                DETAILS: `${baseClientUrl}/${clientId}/details`,
                MAPS: `${baseClientUrl}/${clientId}/maps`,
                MAP_CREATE: `${baseClientUrl}/${clientId}/map/new`,
                PERMISSIONS:  `${baseClientUrl}/${clientId}/permissions`,
                API_TOKENS:  `${baseClientUrl}/${clientId}/tokens`,
                FILE_MANAGER: `${baseClientUrl}/${clientId}/files`,
                PICKING_REQUESTS:  `${baseClientUrl}/${clientId}/picking/requests`,
                TEST_MIDDLEWARE:  `${baseClientUrl}/${clientId}/test/middlewares`, 

                MAP_AUDITS:  (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/audits`,
                MAP_DETAILS: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/details`,
                MAP_VIEWER_3D: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/viewer/3d`,
                MAP_PICKING_SETTING: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/picking/settings`,
                MAP_3D_POLYGONS: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/polygons`,
                MAP_3D_ASSETS: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/assets`,
                MAP_3D_ASSET_TEMPLATE: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/asset/template`,
                MAP_3D_BUILDINGS: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/buildings`,
                MAP_3D_MARKERS: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/markers`,
                MAP_3D_LABELS: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/labels`,
                MAP_3D_LABEL_TEMPLATES: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/label/templates`,
                MAP_3D_MARKER_TEMPLATES: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/marker/templates`,
                MAP_3D_FLOORS: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/floors`,
                MAP_3D_OFFMESHLINKS: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/offmeshlinks`,
                MAP_3D_NAVMESH: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/navmeshes`,
                MAP_3D_SETTING: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/settings`,
                MAP_3D_TEMPLATES: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/templates`,
                MAP_3D_ZONE: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/zones`,
                MAP_3D_METADATA_GROUPS: (mapId: string) => `${baseClientUrl}/${clientId}/map/${mapId}/3d/metadata/groups`,
            }
        }
    }
}