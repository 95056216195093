

/**
 * Fetches a file from a given URL and returns a Promise resolving to a File object
 * created from the downloaded blob.
 * @param {string} url - The URL of the file to download.
 * @returns {Promise<File>} A Promise resolving to a File object created from the downloaded blob.
 */
export default async function readFileFromUrl(url: string):  Promise<File> {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch file from ${url}: ${response.statusText}`);
        }

        const blob = await response.blob();

        const filename = url.split('/').pop() || 'downloaded-file';

        return new File([blob], filename, { type: blob.type });
    } catch (error) {
        console.error("Error fetching file:", error);
        throw error;
    }
}

