import { Account } from "./ApiBackofficeDefinition/Account";
import { Authentication } from "./ApiBackofficeDefinition/Authentication";
import { Map } from "./ApiBackofficeDefinition/Map";
import { MapAsset } from "./ApiBackofficeDefinition/MapAsset";
import { Storage } from "./ApiBackofficeDefinition/Storage";
import { AuditFile } from "./ApiBackofficeDefinition/AuditFile";
import getApiConfig from "./utils/getApiConfig";
import { PickingOptimizationResult } from "./ApiBackofficeDefinition/PickingOptimizationResult";
import { MapBuilding } from "./ApiBackofficeDefinition/MapBuilding";
import { MapFloor } from "./ApiBackofficeDefinition/MapFloor";
import { MapMarker } from "./ApiBackofficeDefinition/MapMarker";
import { MapMarkerTemplate } from "./ApiBackofficeDefinition/MapMarkerTemplate";
import { MetadataGroup } from "./ApiBackofficeDefinition/MetadataGroup";
import { MapPolygon } from "./ApiBackofficeDefinition/MapPolygon";
import { MapNavMesh } from "./ApiBackofficeDefinition/MapNavMesh";
import { MapOffMeshLink } from "./ApiBackofficeDefinition/MapOffMeshLink";
import { MapTemplate } from "./ApiBackofficeDefinition/MapTemplate";
import { MapZone } from "./ApiBackofficeDefinition/MapZone";
import { Language } from "./ApiBackofficeDefinition/Language";
import { MapSetting } from "./ApiBackofficeDefinition/MapSetting";
import { Middlewares } from "./ApiBackofficeDefinition/Middlewares";
import { MapLabel } from "./ApiBackofficeDefinition/MapLabel";
import { LabelTemplate } from "./ApiBackofficeDefinition/LabelTemplate";
import { HeatMapPath } from "./ApiBackofficeDefinition/HeatMapPath";
import { HeatMapPoint } from "./ApiBackofficeDefinition/HeatMapPoint";
import { HeatMapGenerate } from "./ApiBackofficeDefinition/HeatMapGenerate";
import { MapAssetTemplate } from "./ApiBackofficeDefinition/MapAssetTemplate";


export default {
    auth: new Authentication(getApiConfig()),
    account: new Account(getApiConfig()),
    map: new Map(getApiConfig()),
    language: new Language(getApiConfig()),
    storage: new Storage(getApiConfig()),
    asset: new MapAsset(getApiConfig()),
    assetTemplate: new MapAssetTemplate(getApiConfig()),
    label: new MapLabel(getApiConfig()),
    labelTemplate: new LabelTemplate(getApiConfig()),
    marker: new MapMarker(getApiConfig()),
    markerTemplate: new MapMarkerTemplate(getApiConfig()),
    floor: new MapFloor(getApiConfig()),
    building: new MapBuilding(getApiConfig()),
    metadataGroup: new MetadataGroup(getApiConfig()),
    auditFile: new AuditFile(getApiConfig()),
    polygon: new MapPolygon(getApiConfig()),
    navmesh: new MapNavMesh(getApiConfig()),
    offmeshLink: new MapOffMeshLink(getApiConfig()),
    template: new MapTemplate(getApiConfig()),
    zone: new MapZone(getApiConfig()),
    pickingOptimizationResult: new PickingOptimizationResult(getApiConfig()),
    mapSetting: new MapSetting(getApiConfig()),
    middleware: new Middlewares(getApiConfig()),
    heatmap: {
        path: new HeatMapPath(getApiConfig()),
        point: new HeatMapPoint(getApiConfig()),
        generate: new HeatMapGenerate(getApiConfig())
    }
}