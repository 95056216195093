import i18next from "i18next";
import { toast } from "react-toastify";
import { adminClientMapAssetTemplateActions } from "../adminClientMapAssetTemplateSlice";
import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";


/**
 * Thunk action creator for loading AssetTemplates
 * @param mapId 
 * @returns 
 */
export const loadAssetTemplatesThunks = (): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(adminClientMapAssetTemplateActions.setLoadingAssetTemplateList(true));
  
        // Fetch AssetTemplates from the API
        const clientMaps = await apiBackofficeDefinition.assetTemplate.assetTemplatesList();
  
        // Dispatch action to set the fetched AssetTemplates in the store
        dispatch(adminClientMapAssetTemplateActions.setAssetTemplates(clientMaps.data));
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        if (!error.status || error.status != 401) {
          // Display error notification if it's not an unauthorized error
          toast.error(i18next.t("Erreur lors du chargement des AssetTemplates de la map"));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(adminClientMapAssetTemplateActions.setLoadingAssetTemplateList(false));
      }
    };
  };