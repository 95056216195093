import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AssetTemplateDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

// Interface representing the state of the admin client map AssetTemplate store
export interface IAdminClientMapAssetTemplateStore {
  AssetTemplates: AssetTemplateDetails[];
  loadingList: boolean;
  loadingForm: boolean;
}

// Initial state for the admin client map AssetTemplate store
const initialState: IAdminClientMapAssetTemplateStore = {
  AssetTemplates: [],
  loadingList: false,
  loadingForm: false,
};

// Create a slice for the admin client map AssetTemplate store
const adminClientMapAssetTemplateSlice = createSlice({
  name: "adminClientMapAssetTemplateSlice",
  initialState,
  reducers: {
    // Set the AssetTemplates in the state
    setAssetTemplates: (state, action: PayloadAction<AssetTemplateDetails[]>) => {
      state.AssetTemplates = action.payload;
    },
    // Update a specific AssetTemplate in the state
    updateAssetTemplate: (state, action: PayloadAction<AssetTemplateDetails>) => {
      state.AssetTemplates = state.AssetTemplates.map((p) =>
        p.id === action.payload.id ? action.payload : p
      );
    },
    // Add a new AssetTemplate to the state
    addAssetTemplate: (state, action: PayloadAction<AssetTemplateDetails>) => {
      state.AssetTemplates.push(action.payload);
    },
    // remove an AssetTemplate
    deleteAssetTemplate: (state, action: PayloadAction<string>) => {
      state.AssetTemplates = state.AssetTemplates.filter(m => m.id !== action.payload)
    },
    // Set the loadingList state
    setLoadingAssetTemplateList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    // Set the loadingForm state
    setLoadingAssetTemplateForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state.AssetTemplates = [];
      state.loadingList = false;
      state.loadingForm = false;
    });
  },
});

// Export the actions and reducer from the admin client map AssetTemplate slice
export const {
  actions: adminClientMapAssetTemplateActions,
  reducer: adminClientMapAssetTemplateReducer,
} = adminClientMapAssetTemplateSlice;
